.spinner {
	display: inline-block;
	background: transparent;
	border-radius: 50%;
	border-style: solid;
	animation: spinner1 800ms ease infinite;
}
.spinner.slow {
	animation-duration: 2s;
}
.spinner.linear {
	animation-timing-function: linear;
}
@keyframes spinner1 {
	to {
		transform: rotate(360deg);
	}
}