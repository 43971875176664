/*

!* Light skin ------------------------------------------------------------------------------------------------------- *!
html[data-skin-mode='skin-light'] {
	--default-app-main-color: #517dd3;
	--default-app-main-color-h: 220;
	--default-app-main-color-s: 60%;
	--default-app-main-color-l: 57%;
	--default-app-main-color-gradient: linear-gradient(to left, #75b0f0, #6aa3e9, #6097e2, #588adb, #517dd3);
	--default-app-main-color-contrast: #fff;
}
html[data-skin-mode='skin-light'] #default-app {
	--action-color-main: var(--default-app-main-color);
	--action-color-contrast-main: var(--default-app-main-color-contrast);
	--action-color-faded: hsla(var(--default-app-main-color-h), calc(var(--default-app-main-color-s) + 100%), var(--default-app-main-color-l), 0.15);
	--action-color-hover: hsl(var(--default-app-main-color-h), var(--default-app-main-color-s), calc(var(--default-app-main-color-l) - var(--hover-intensity)));

	--sidebar-bkg-color: hsl(var(--default-app-main-color-h), calc(var(--default-app-main-color-s) - 15%), calc(var(--default-app-main-color-l) - 15%));
	--sidebar-hover-bkg-color: rgba(0, 0, 0, 0.15);
	--sidebar-selected-bkg-color: hsl(var(--default-app-main-color-h), calc(var(--default-app-main-color-s) - 15%), calc(var(--default-app-main-color-l) + 40%));
	--sidebar-text-color: var(--default-app-main-color-contrast);
	--sidebar-hover-text-color: var(--sidebar-text-color);
	--sidebar-selected-text-color: var(--default-app-main-color);
	--sidebar-sub-bkg-color: hsl(var(--default-app-main-color-h), calc(var(--default-app-main-color-s) - 30%), calc(var(--default-app-main-color-l) - 30%));
	--sidebar-sub-hover-bkg-color: var(--sidebar-hover-bkg-color);
	--sidebar-sub-selected-bkg-color: var(--sidebar-selected-bkg-color);
	--sidebar-sub-text-color: var(--sidebar-text-color);
	--sidebar-sub-hover-text-color: var(--sidebar-hover-text-color);
	--sidebar-sub-selected-text-color: var(--sidebar-selected-text-color);
	--sidebar-border-color: hsl(var(--default-app-main-color-h), calc(var(--default-app-main-color-s) - 15%), calc(var(--default-app-main-color-l) + 30%));
	--sidebar-border: 1px solid var(--sidebar-border-color);
	--sidebar-selected-border-color: hsl(var(--default-app-main-color-h), calc(var(--default-app-main-color-s) - 30%), calc(var(--default-app-main-color-l) - 30%));
	--sidebar-sub-selected-border-color: var(--sidebar-selected-border-color);
	
	--header-text-color: var(--default-app-main-color);
}


!* Dark skin ------------------------------------------------------------------------------------------------------- *!
html[data-skin-mode='skin-dark'] {
	--default-app-main-color: #79a3f3;
	--default-app-main-color-h: 219;
	--default-app-main-color-s: 84%;
	--default-app-main-color-l: 71%;
	--default-app-main-color-gradient: linear-gradient(to left, #3b74be, #386bb2, #3562a6, #32599a, #2e518e);
	--default-app-main-color-contrast: #fff;
}
html[data-skin-mode='skin-dark'] #default-app {
	--action-color-main: var(--default-app-main-color);
	--action-color-contrast-main: var(--default-app-main-color-contrast);
	--action-color-faded: hsla(var(--default-app-main-color-h), calc(var(--default-app-main-color-s) + 100%), var(--default-app-main-color-l), 0.15);
	--action-color-hover: hsl(var(--default-app-main-color-h), var(--default-app-main-color-s), calc(var(--default-app-main-color-l) - var(--hover-intensity)));
	
	--sidebar-selected-bkg-color: var(--action-color-faded);
	--sidebar-text-color: var(--default-app-main-color);
	--sidebar-hover-text-color: var(--default-app-main-color);
	--sidebar-selected-text-color: var(--default-app-main-color);
	--sidebar-selected-border-color: var(--default-app-main-color);
	--sidebar-sub-selected-border-color: var(--sidebar-selected-border-color);
	--sidebar-sub-selected-bkg-color: var(--sidebar-selected-bkg-color);
	--sidebar-sub-text-color: var(--sidebar-text-color);
	--sidebar-sub-hover-text-color: var(--sidebar-hover-text-color);
	
	--header-text-color: var(--default-app-main-color);
}


!* Any skin --------------------------------------------------------------------------------------------------------- *!
.dropdown-component.app-DEFAULT .dropdown-item {
	color: var(--default-app-main-color);
}
.dropdown-item.app-DEFAULT {
	color: var(--default-app-main-color);
}
.card.action-card.colored.app-DEFAULT {
	background-image: var(--default-app-main-color-gradient);
}
*/
