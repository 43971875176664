/* Messages --------------------------------------------------------------------------------------------------------- */
.message-component {
	transform: translate(0px, 40px); 
	opacity: 0;
	transition: all 100ms ease-out;
}
.message-transitions-enter-done { 
	transform: translate(0px, 0px); 
	opacity: 1; 
}
.message-transitions-exit { 
	transform: translate(0px, 0px); 
	opacity: 1; 
}
.message-transitions-exit-active { 
	transform: translate(0px, 40px); 
	opacity: 0; 
}


/* Dialogs ---------------------------------------------------------------------------------------------------------- */
.dialog-transitions-enter .dialog-content { 
	transform: scale(0.9); 
	opacity: 0; 
}
.dialog-transitions-enter-active .dialog-content { 
	transform: scale(1); 
	opacity: 1; 
	transition: all 200ms ease-out; 
}
.dialog-transitions-exit .dialog-content { 
	transform: scale(1); 
	opacity: 1; 
}
.dialog-transitions-exit-active .dialog-content { 
	transform: scale(0.9); 
	opacity: 0; 
	transition: all 100ms ease-out; 
}

.dialog-transitions-enter .dialog-overlay {
	opacity: 0;
}
.dialog-transitions-enter-active .dialog-overlay {
	opacity: 1;
	transition: all 200ms ease-out;
}
.dialog-transitions-exit .dialog-overlay {
	opacity: 1;
}
.dialog-transitions-exit-active .dialog-overlay {
	opacity: 0;
	transition: all 100ms ease-out;
}

/* Popups ----------------------------------------------------------------------------------------------------------- */
.popups {
	background-color: var(--popup-overlay-transparent);
	transition: background-color 200ms ease-out;
}
.popups.empty {
	display: none;
}
.popups-transitions-exit {
	background-color: transparent;
	transition: background-color 200ms ease-in;
}

.popup-transitions-enter {
	-webkit-transform: translateY(100%); 
	transform: translateY(100%); 
	opacity: 0;
}
.popup-transitions-enter-active { 
	-webkit-transform: translateY(0); 
	transform: translateY(0);
	opacity: 1;
	transition: all 200ms ease-out; 
}
.popup-transitions-exit { 
	-webkit-transform: translateY(0); 
	transform: translateY(0);
	opacity: 1;
}
.popup-transitions-exit-active { 
	-webkit-transform: translateY(100%); 
	transform: translateY(100%);
	opacity: 0;
	transition: all 200ms ease-out; 
}

/* Blur */
.blur {
	transition: filter 150ms ease-out;
}
.no-blur {
	transition: filter 150ms ease-in;
}