.wrapper {
	
}

.wrapper .title {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	box-shadow: var(--hover-clear-box-shadow);
	font-size: 1em;
}
.wrapper .title:hover {
	box-shadow: var(--hover-box-shadow);
}
.wrapper .title .toolbar {
	flex: 0 0 auto;
}
.wrapper .title .toolbar > button {
	font-size: .75em;
}

.wrapper .content {
	
}