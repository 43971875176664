/* Create button in the form field label */
.patient-quick-search .form-field-label.with-actions {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	column-gap: 20px;
	row-gap: 10px;
}
.patient-quick-search .form-field-label.with-actions > .content {
	flex: 1 1 auto;
	min-width: 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	transition: font-size 100ms ease-out;
	line-height: 1;
}
.patient-quick-search .form-field-label.with-actions > .actions {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	column-gap: 10px;
}
.patient-quick-search .form-field-label.with-actions > .actions button {
	display: block;
	margin: 0;
}


/* Search button */
.patient-quick-search .form-field-input .input-toolbar.position-left .search-btn {
	padding-left: 15px;
	padding-right: calc(.5em - 2px);
	font-size: 18px;
	color: var(--text-color-main);
}


/* Create button in the select input */
.patient-quick-search .form-field-input.with-actions .select-input__indicators {
	border-right: var(--form-control-border);
}
.patient-quick-search .form-field-input.with-actions .input-toolbar.position-right {
	align-self: stretch;
}
.patient-quick-search .form-field-input.with-actions .input-toolbar.position-right button {
	padding-left: 1em;
	padding-right: 1em;
}


/* Responsive */
@media screen and (max-width: 810px) {
	.patient-quick-search .form-field-label.with-actions .actions {
		flex-basis: 100%;
		flex-flow: row-reverse;
		margin-bottom: calc(var(--padding-section) / 4);
	}
	.patient-quick-search .form-field-label.with-actions .actions .action-button.main-button {
		flex-basis: 100%;
		order: 99;
	}
	.patient-quick-search .form-field-label.with-actions .actions .action-button.close-button {
		order: -99;
	}
	.patient-quick-search .form-field-label.with-actions .actions .action-button.main-button > *[data-tooltipped] {
		width: 100%;
	}
	.patient-quick-search .form-field-label.with-actions .actions .action-button.main-button .button,
	.patient-quick-search .form-field-label.with-actions .actions .action-button.main-button .button-component {
		width: 100%;
		text-align: center;
	}
}
@media screen and (max-width: 480px) {
	.patient-quick-search .form-field-label.with-actions .actions .action-button .button,
	.patient-quick-search .form-field-label.with-actions .actions .action-button .button-component {
		font-size: .875rem;
	}
	.patient-quick-search .form-field-label.with-actions > .content {
		font-size: 1.5rem;
	}
	.patient-quick-search .form-field-label.with-actions > .actions {
		margin-bottom: 0;
	}
}