.messages {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	position: fixed;
	bottom: 10px;
	left: 0;
	width: 100vw;
	pointer-events: none;
	touch-action: none;
	z-index: var(--z-index-messages);
}

.empty {
	width: 0;
	height: 0;
}