.dialogs {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-dialog);
}

.empty {
	height: 0;
	width: 0;
}