#patient-record-payments-table thead th:nth-child(3) > .content,
#patient-record-payments-table thead th:nth-child(4) > .content,
#patient-record-payments-table thead th:nth-child(5) > .content,
#patient-record-payments-table thead th:nth-child(6) > .content
{
	justify-content: flex-end;
	text-align: right;
}
#patient-record-payments-table tbody td:nth-child(6) > .content {
	text-align: right;
	white-space: nowrap;
}