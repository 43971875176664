/* Overlay */
.overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	font-size: 1em;
	filter: blur(0);
	z-index: 2;
	color: var(--text-color-overlay);
	border-radius: var(--dialog-border-radius);
}
.overlay.transparent {
	background: var(--background-overlay-transparent);
}
.overlay.opaque {
	background: var(--background-overlay-opaque);
}
.empty {
	display: none;
}

/* Loading overlay */
.overlay.loading {
	z-index: var(--z-index-loading);
}
.overlay.loading i {
	font-size: 1em;
}