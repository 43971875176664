/*noinspection CssInvalidAtRule*/
@import-normalize;

a {
	color: var(--action-color-main);
	transition: var(--transition-hover-in);
}
a:hover {
	transition: var(--transition-hover-out);
}

h1 {
	font-weight: 200;
}

p {
	margin: .5em 0;
}

:where(b, strong) {
	font-weight: bold;
}