.wrapper {
	--toolbar-size: 40px;
}

.wrapper .lists {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	column-gap: calc(var(--toolbar-size) / 2);
	row-gap: var(--padding-section);
	height: calc(100% - var(--toolbar-size));
}
.wrapper .listToolbar {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	height: var(--toolbar-size);
	font-weight: bold;
}
.wrapper .listToolbar .toolbarLabel {
	flex: 1;
	justify-content: left;
	line-height: 1;
}
.wrapper .list {
	flex: 1;
}

@media screen and (max-width: 1024px) {
	.wrapper .listToolbar .toolbarLabel {
		font-size: 0.875em;
	}
}
@media screen and (max-width: 820px) {
	.wrapper .lists {
		flex-flow: column nowrap;
	}
	.wrapper .list {
		flex: auto;
	}
}