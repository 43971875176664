.wrapper {
	position: fixed;
	z-index: 2;
	display: flex;
	flex-flow: column nowrap;
	background: var(--float-popup-background);
	color: var(--float-popup-text);
	border: var(--float-popup-border);
	box-shadow: var(--float-popup-box-shadow);
	font-size: var(--float-popup-font-size);
	overflow: auto;
}

.wrapper.position-top-left {
	top: var(--float-popup-position-offset);
	left: var(--float-popup-position-offset);
}
.wrapper.position-top-right {
	top: var(--float-popup-position-offset);
	right: var(--float-popup-position-offset);
	
}
.wrapper.position-bottom-left {
	bottom: var(--float-popup-position-offset);
	left: var(--float-popup-position-offset);
}
.wrapper.position-bottom-right {
	bottom: var(--float-popup-position-offset);
	right: var(--float-popup-position-offset);
}

.wrapper .header {
	position: sticky;
	top: 0;
	left: 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	padding-left: var(--float-popup-padding);
	border-bottom: var(--float-popup-border);
	font-size: 1rem;
	font-weight: 600;
	background: var(--float-popup-background);
	z-index: 1;
}
.wrapper .header .close {
	padding: var(--float-popup-padding);
	cursor: pointer;
}
.wrapper .header .icon {
	color: var(--float-popup-close-text);
}
.wrapper .header .close:hover .icon {
	color: var(--float-popup-close-text-hover);
}
.wrapper .header .title {
	padding: var(--float-popup-padding) 0;
}

