body.popups-visible .float-popup-component.position-bottom-right {
	bottom: calc(55px + var(--float-popup-position-offset));
}

.float-popup-component .float-popup-content .float-popup-content-component > .content {
	padding: var(--float-popup-padding);
}

.float-popup-component .float-popup-actions {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: center;
	padding: var(--float-popup-padding);
}
.float-popup-component .float-popup-actions.sticky {
	position: sticky;
	bottom: 0;
	left: 0;
	border-top: var(--float-popup-border);
	background: var(--float-popup-background);
	z-index: 1;
}
.float-popup-component .float-popup-actions button,
.float-popup-component .float-popup-actions .button {
	margin-left: 12px;
}
.float-popup-component .float-popup-actions button:first-of-type,
.float-popup-component .float-popup-actions .button:first-child {
	margin-left: 0;
}