/* Layout backgrounds ----------------------------------------------------------------------------------------------- */
.layout.background-image {
	background-image: url("../images/bkg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.layout.background-image-error {
	background-image: url("../images/bkg_error.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}


/* Default layout alternative styles */
.layout-default.flex .layout-content {
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: calc(100% - var(--header-size));
	display: flex;
	flex-flow: column nowrap;
}
.layout-default.flex .layout-content:before,
.layout-default.flex .layout-content > .page-title {
	flex: 0 0 auto;
}
.layout-default.flex .layout-content > .page-content {
	flex: 1 1 100%;
	display: flex;
	flex-flow: column nowrap;
	min-height: 0;
}
@media screen and (max-width: 768px) {
	.layout-default.flex .layout-content::before,
	.layout-default.flex .layout-content > .page-title,
	.layout-default.flex .layout-content > .page-content {
		display: block;
		flex: 0 0 auto;
	}
}


/* Layout other ----------------------------------------------------------------------------------------------------- */
.simple-page-description {
	margin-bottom: var(--padding-section);
	color: var(--text-color-faded);
	max-width: 800px;
	line-height: 1.2;
	font-weight: 300;
}
.simple-subtitle-description {
	margin-bottom: 1em;
	color: var(--text-color-faded);
	max-width: 800px;
	line-height: 1.2;
	font-weight: 300;
}
.card.page-description {
	margin-bottom: 2.5em;
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 1.3;
	border: none;
	border-left: 3px solid var(--palette-sky);
}
.card.page-description .card-content {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	color: var(--text-color-subtle);
	line-height: 1.25;
}
.card.page-description .card-content .card-icon {
	flex: 0 0 32px;
	font-size: 32px;
	margin-right: 15px;
	text-align: center;
	color: var(--text-color-faded);
	display: flex;
	justify-content: center;
	align-items: center;
}
.card.page-description .card-content strong {
	font-weight: 600;
}

@media screen and (min-width: 481px) {
	.simple-page-description {
		margin-top: -15px; /* Negative since page title that is right above it has a bottom margin */
	}
}
@media screen and (min-width: 1025px) and (max-height: 768px) {
	.form-wrapper .form-field.label-position-aligned {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.layout.layout-with-table .layout-right .layout-content .layout-page > .popups {
		width: 100%;
		left: 0;
	}

	.layout.layout-with-table .layout-right .layout-content {
		padding-left: 1px;
		padding-right: 1px;
	}
	.layout.layout-with-table .layout-right .layout-content .layout-page:before {
		height: 10px;
	}
	.layout.layout-with-table .layout-right .layout-content .layout-page:after {
		height: 1px;
	}
	.layout.layout-with-table .layout-right .layout-content .layout-page .page-title {
		padding-left: calc(var(--padding-section) / 2);
		padding-right: calc(var(--padding-section) / 2);
	}

	#main-page-table table thead .select .content,
	#main-page-table table .select-checkbox,
	#main-page-table table tbody .content,
	#main-page-table table tbody .actions .action-btn,
	#main-page-table table tbody .action .action-btn {
		padding-top: 0.6vh;
		padding-bottom: 0.6vh;
		line-height: 1.2;
	}
	#main-page-table table tbody .select-checkbox {
		padding-top: calc(0.6vh + 5px);
		padding-bottom: calc(0.6vh + 5px);
	}
	#main-page-table table tbody td.select > .content {
		padding: 0;
	}
}

@media screen and (orientation: portrait) {
	.dialog-component .dialog-content {
		max-height: calc(100vh - var(--padding-section) - 30px);
		max-height: calc(100dvh - var(--padding-section) - 30px);
		margin: calc((var(--padding-section) / 2) + 30px) calc(var(--padding-section) / 2) calc(var(--padding-section) / 2);
	}
}


/* Main sidebar menu */
#layout-main-sidebar .main-menu {
	padding: 0 var(--sidebar-padding-x) var(--sidebar-padding-y);
	scrollbar-width: thin;
}
#layout-main-sidebar .main-menu a,
#layout-main-sidebar .main-menu p {
	font-size: var(--main-sidebar-font-size);
	font-weight: normal;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	text-decoration: none;
	padding: var(--main-sidebar-padding-y) var(--main-sidebar-padding-x);
	box-shadow: inset 0 0 0 999em transparent;
	transition: var(--transition-hover-out);
	color: var(--sidebar-text-color);
	border-left: var(--main-sidebar-overhead) solid transparent;
	border-right: var(--main-sidebar-overhead) solid transparent;
	line-height: 1;
	border-radius: var(--sidebar-item-border-radius);
}
#layout-main-sidebar .main-menu a.active {
	background: var(--sidebar-selected-bkg-color);
	border-color: var(--sidebar-selected-border-color);
	color: var(--sidebar-selected-text-color);
}
#layout-main-sidebar .main-menu .group-menu-item.active-child > :first-child {
	color: var(--sidebar-selected-text-color);
}
#layout-main-sidebar .main-menu a:not(.active):hover,
#layout-main-sidebar .main-menu p:not(.active):hover {
	box-shadow: inset 0 0 0 999em var(--sidebar-hover-bkg-color);
	border-color: var(--sidebar-hover-bkg-color);
	color: var(--sidebar-hover-text-color);
}
#layout-main-sidebar .main-menu a:hover,
#layout-main-sidebar .main-menu p:hover {
	opacity: 1;
}
#layout-main-sidebar .main-menu a .icon,
#layout-main-sidebar .main-menu p .icon,
#layout-main-sidebar .main-menu a .fa-stack,
#layout-main-sidebar .main-menu p .fa-stack {
	flex: 0 0 1em;
	margin: 0 0.5em 0 0;
	font-size: 1.25em;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--sidebar-icon-color);
}
#layout-main-sidebar .main-menu a .fa-stack .icon,
#layout-main-sidebar .main-menu p .fa-stack .icon {
	margin: 0;
	text-align: unset;
	font-size: 1em;
}
#layout-main-sidebar .main-menu a.active .icon,
#layout-main-sidebar .main-menu p.active .icon,
#layout-main-sidebar .main-menu a.active .fa-stack,
#layout-main-sidebar .main-menu p.active .fa-stack,
#layout-main-sidebar .main-menu .group-menu-item.active-child > :first-child > .icon:first-child {
	color: var(--sidebar-selected-text-color);
}
#layout-main-sidebar .main-menu .separator {
	font-size: 0.75em;
	font-weight: 300;
	color: var(--sidebar-icon-color);
	margin: 2em 0 0.5em;
	padding-bottom: 0;
	text-transform: uppercase;
}
#layout-main-sidebar.shrank .main-menu .separator {
	visibility: hidden;
	height: 0;
	margin-bottom: 0;
	width: 100%;
	font-size: 0.7em;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}
body.mobile .layout .main-sidebar-size-toggle {
	display: none;
}

body:not(.mobile) #layout-main-sidebar {
	transition: width 100ms ease-in;
}
body:not(.mobile) #layout-main-sidebar.shrank {
	width: var(--sidebar-width-shrank);
	transition: width 100ms ease-out;
}
body:not(.mobile) #layout-main-sidebar.shrank img.logo {
	margin-bottom: 0.75em;
}
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a,
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p {
	justify-content: center;
	padding: 0;
	height: calc(var(--sidebar-width-shrank) - (var(--sidebar-padding-y) * 2));
	width: calc(var(--sidebar-width-shrank) - (var(--sidebar-padding-x) * 2));
}
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a > .icon-stack,
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p > .icon-stack,
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a > .icon:not(.stacked-1):not(.stacked-2),
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p > .icon:not(.stacked-1):not(.stacked-2)
{
	margin: 0;
	font-size: calc(var(--sidebar-width-shrank) * 0.3);
}
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a > span:not(.icon-stack),
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p > span:not(.icon-stack)
{
	display: none;
}
#layout-main-sidebar .main-menu .group-menu-item > a,
#layout-main-sidebar .main-menu .group-menu-item > p {
	position: relative;
}
#layout-main-sidebar .main-menu .group-menu-item .menu-item-group-toggle:focus,
#layout-main-sidebar .main-menu .group-menu-item .menu-item-group-toggle:active {
	outline: none;
	box-shadow: none;
	border-color: transparent;
}
#layout-main-sidebar .main-menu .group-menu-item > a > .menu-item-group-toggle,
#layout-main-sidebar .main-menu .group-menu-item > p > .menu-item-group-toggle {
	position: absolute;
	top: 0;
	left: calc(0px - var(--main-sidebar-overhead));
	width: 2em;
	height: 100%;
	padding: 0;
	margin: 0;
	border: none;
	font-size: var(--main-sidebar-group-toggle-size);
	text-align: center;
	z-index: 1;
}
#layout-main-sidebar .main-menu .group-menu-item > a,
#layout-main-sidebar .main-menu .group-menu-item > p {
	padding-left: calc(1em + var(--main-sidebar-overhead) + .5em);
}
body[data-layout-main-sidebar-shrank='true'] #layout-main-sidebar .main-menu .menu-item-group-toggle {
	display: none;
}
#layout-main-sidebar .main-menu .menu-item-group-toggle > .icon {
	margin: 0;
}
#layout-main-sidebar .main-menu .group-menu-item > .group-menu-item-children {
	display: none;
}
body[data-layout-main-sidebar-shrank='false'] #layout-main-sidebar .main-menu .group-menu-item.opened > .group-menu-item-children {
	display: block;
}

body[data-layout-main-sidebar-shrank='false'] #layout-main-sidebar .main-menu .indent-menu-item {
	padding-left: calc(var(--main-sidebar-group-toggle-size) + (var(--main-sidebar-overhead) * 2) + .5em);
	position: relative;
}
body[data-layout-main-sidebar-shrank='false'] #layout-main-sidebar .main-menu .indent-menu-item:before {
	content: "";
	position: absolute;
	left: var(--main-sidebar-group-toggle-size);
	top: 0;
	width: 0;
	height: 100%;
	border-left: 1px dotted var(--sidebar-dotted-border-color);
}

body.dialogs-visible .layout-content, body.popups-visible .layout-content {
	overflow: hidden;
}

.header-left .header-item-wrapper:last-child > .dropdown-content {
	left: 0;
}
.header-right .header-item-wrapper > .dropdown-content {
	z-index: calc(var(--z-index-popup) + 10);
}

.page-above-title {
	margin-top: calc(0px - var(--padding-section) / 2);
	color: var(--text-color-faded);
}

.page-title {
	position: sticky;
	top: -1px;
	left: 0;
	z-index: 1;
	padding: 10px 0;
	margin-top: 0;
	margin-bottom: calc(0.67em - 10px);
	line-height: 1;
}
.page-title.stuck {
	background: var(--background-color-main);
	box-shadow: var(--page-title-box-shadow),
	-5px 0 0 var(--background-color-main),
	5px 0 0 var(--background-color-main);
	border-bottom: 1px solid var(--page-title-border-color);
	padding-top: 10px;
	z-index: 2;
}
.page-title > .content {
	transition: font-size 100ms ease-out;
	line-height: 1;
}
.page-title.stuck > .content {
	font-size: 0.75em;
	padding: calc(0.167em + 1px) 0;
	transition: font-size 100ms ease-in;
}
.page-title.with-actions {
	display: flex;
	flex-flow: row wrap;
	column-gap: 20px;
	row-gap: 10px;
}
.page-title.stuck.with-actions {
	align-items: center;
	padding-bottom: 8px;
	row-gap: 10px;
}
.page-title.with-actions .content {
	flex: 1 1 auto;
	min-width: 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
.page-title.with-actions .actions {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	column-gap: 10px;
}
.page-title small {
	vertical-align: super;
	font-size: 0.5em;
	margin: 0 .5em;
	line-height: 1;
}
@media screen and (max-width: 810px) {
	.page-title.with-actions:not(.reload-only) .actions {
		flex-basis: 100%;
		flex-flow: row-reverse;
		margin-bottom: calc(var(--padding-section) / 4);
	}
	.page-title.with-actions.reload-only .content {
		flex: 1;
	}
	.page-title.with-actions.stuck .content {
		padding-top: 0;
		padding-bottom: 0;
	}
	.page-title.with-actions.stuck .actions {
		margin-bottom: 0;
	}
	.page-title.with-actions .actions .action-button.main-button {
		flex-basis: 100%;
		order: 99;
	}
	.page-title.with-actions .actions .action-button.close-button {
		order: -99;
	}
	.page-title.with-actions .actions .action-button.main-button > *[data-tooltipped],
	.page-title.with-actions .actions .action-button.main-button > *[data-tooltipped] > .advanced-dropdown {
		width: 100%;
	}
	.page-title.with-actions .actions .action-button.main-button .button,
	.page-title.with-actions .actions .action-button.main-button .button-component {
		width: 100%;
		text-align: center;
	}
}
@media screen and (max-width: 480px) {
	.page-title.with-actions:not(.reload-only):has(.xs-flex-1) .actions {
		flex-flow: row wrap;
		row-gap: 10px;
	}
	.page-title.with-actions .actions .action-button.xs-flex-1 {
		flex: 1;
	}
	.page-title.with-actions .actions .action-button.xs-flex-1 .button,
	.page-title.with-actions .actions .action-button.xs-flex-1 .button-component,
	.page-title.with-actions .actions .action-button.xs-flex-1 > [data-tooltipped] {
		width: 100%;
		text-align: center;
	}
	.page-title.with-actions .actions .action-button .button,
	.page-title.with-actions .actions .action-button .button-component {
		font-size: .875rem;
	}
}

.page-title.with-actions .actions .action-button,
.page-title.with-actions .actions .action-button > [data-tooltipped] {
	display: flex !important;
	align-items: center !important;
	white-space: nowrap;
}
.page-title.with-actions .actions .action-button .button,
.page-title.with-actions .actions .action-button .button-component {
	margin: 0;
}
.page-title.with-actions .actions .form-control {
	font-size: 1rem;
	border-right: 4px;
}

.page-title .breadcrumbs {
	font-size: .875rem;
}
.page-title span.breadcrumb:last-of-type {
	display: block;
	font-size: 2rem;
	margin-top: 0.25em;
}
.page-title.stuck > .content span.breadcrumb:last-of-type {
	font-size: 1.5rem;
	transition: font-size 100ms ease-in;
}
@media screen and (max-width: 480px) {
	.page-title.with-actions {
		margin-bottom: 0;
	}
	.page-title span.breadcrumb:last-of-type,
	.page-title.with-actions .content {
		font-size: 1.5rem;
	}
}

.page-subtitle {
	padding: 10px 0 0;
	margin-top: calc(var(--padding-section) / 2);
	margin-bottom: calc(0.67em - 10px);
	line-height: 1;
	font-size: 1.25em;
}

/* Main content */
.layout-page > .page-content {
	position: relative;
	z-index: 0;
}

/* Content sidebar */
.layout-content-sidebar {
	font-size: 14px;
	height: 100%;
	background: var(--content-sidebar-background);
	color: var(--content-sidebar-text-color);
	overflow: auto;
	align-self: flex-start;
}
.layout-content-sidebar.left {
	border-right: var(--content-sidebar-border);
}
.layout-content-sidebar.right {
	border-left: var(--content-sidebar-border);
}
.layout-content-sidebar .form-field {
	--input-font-size: 14px;
	--input-height: 32px;
}
.layout-content-sidebar .form-field-label {
	font-weight: normal;
	color: var(--text-color-faded);
	margin-bottom: -0.5em !important;
	position: relative;
	z-index: 2;
	background: #fff;
	align-self: flex-start;
	margin-left: 7px;
	padding-left: 5px;
	padding-right: 5px;
}
.layout-content-sidebar .form-control-wrapper label,
.layout-content-sidebar .form-control {
	padding: .5em;
	background: transparent;
}
.layout-content-sidebar .form-field-input .form-control.radio-input-component {
	padding: .25em;
}
.layout-content-sidebar .form-field-input .form-control.radio-input-component .radio-input-component-option {
	padding: .35em .25em;
}
.layout-content-sidebar .form-field-input .form-control.radio-input-component .radio-input-component-option:not([disable]):not(.disabled):focus {
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
}
.layout-content-sidebar .section {
	margin: 1em 0 0.5em;
}
.layout-content-sidebar .section:first-of-type {
	margin-top: 0;
}
.layout-content-sidebar .section:last-of-type {
	margin-bottom: 0;
}
.layout-content-sidebar .section > .title {
	padding: 7px var(--content-sidebar-padding-x) 0 var(--content-sidebar-padding-x);
	min-height: 32px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	position: sticky;
	top: 0;
	left: 0;
	background: var(--content-sidebar-background);
	box-shadow: 0 10px 20px #fff;
	z-index: 3;
}
.layout-content-sidebar .section > .title .title-label {
	flex: 1 1 auto;
	font-size: 0.75em;
	font-weight: 300;
	text-transform: uppercase;
	color: var(--content-sidebar-text-faded);
}
.layout-content-sidebar .section > .title .action-button {
	border-radius: 0;
	border: none;
	min-width: 24px;
	height: 24px;
	padding: 0 4px;
}
.layout-content-sidebar .section > .content-wrapper {
	padding: 0 var(--content-sidebar-padding-x);
}
.layout-content-sidebar .section > .actions,
.layout-content-sidebar .section > .content-wrapper .actions {
	position: sticky;
	bottom: 0;
	left: 0;
	background: var(--content-sidebar-background);
	box-shadow: 0 -10px 20px #fff;
	z-index: 3;
	padding: 10px 0;
}


/* Blank layout */
.layout-blank .page-content {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}