.wrapper {
	
}

.wrapper .row {
	margin-bottom: .75em;
}
.wrapper .row:last-of-type {
	margin-bottom: 0;
}
.wrapper .row > label,
.wrapper .row > span {
	display: block;
	line-height: 1.25;
}
.wrapper .row > label {
	width: 150px;
	font-size: 0.75em;
	font-weight: 300;
	color: var(--text-color-faded);
}