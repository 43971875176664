.wrapper {
	--collapse-button-width: 45px;
	--collapse-button-size: 34px;
	--collapse-button-minus-offset: -4px;
}

.wrapper .title {
	position: relative;

	/* No select */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.wrapper.collapsable .title {
	cursor: pointer;
	padding-right: var(--collapse-button-width);
}

.wrapper.collapsable .title:after {
	font-size: var(--collapse-button-size);
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: var(--collapse-button-width);
	display: flex;
	justify-content: center;
	align-items: center;
}
.wrapper.collapsable.collapsed .title:after {
	content: '+';
}
.wrapper.collapsable.expanded .title:after {
	content: '–';
	font-size: var(--collapse-button-size);
	margin-top: var(--collapse-button-minus-offset);
}

.wrapper .title .applied {
	color: var(--success-background);
}

.wrapper .contentWrapper {
	
}
.wrapper.expanded .contentWrapper {
	
}
.wrapper.collapsed .contentWrapper {
	display: none;
}

.wrapper .options {
	height: 100%;
	position: relative;
}

.wrapper .options.stacked {
	
}

.wrapper .options.aligned {
	
}
.wrapper .options.aligned .form {
	
}
.wrapper .options.aligned .field {
	--form-field-max-width: none;
	
	flex: 1 1 30%;
	margin-bottom: 15px;
}
.wrapper .options.aligned .field:first-of-type {
	margin-top: 15px;
}

.wrapper .options.aligned .fieldInput {
	max-width: 100%;
}

@media screen and (max-width: 1024px) {
	.wrapper .options.stacked .fieldLabel {
		flex-basis: calc(var(--form-label-width) / 2);
	}
}

@media screen and (max-width: 768px) {
	.wrapper .options.aligned .field {
		flex-basis: 100%;
	}
}