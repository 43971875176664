:root {
	--fc-toolbar-font-size: 14px;
	--fc-toolbar-date-font-size: 22px;
	--fc-font-size: 14px;
	--fc-time-slot-font-size: var(--fc-font-size);
	--fc-small-font-size: var(--fc-font-size);
	--fc-page-bg-color: var(--calendar-background);
	--fc-neutral-bg-color: var(--toolbar-backround);
	--fc-neutral-text-color: #808080;
	--fc-border-color: var(--border-color-main);
	--fc-today-bg-color: hsla(var(--palette-light-purple-h), var(--palette-light-purple-s), var(--palette-light-purple-l), .25);

	--fc-button-text-color: var(--action-color-contrast-main);
	--fc-button-bg-color: var(--action-color-main);
	--fc-button-border-color: var(--action-color-main);
	--fc-button-hover-bg-color: var(--action-color-hover);
	--fc-button-hover-border-color: var(--action-color-hover);
	--fc-button-active-bg-color: var(--action-color-hover);
	--fc-button-active-border-color: var(--action-color-hover);

	--fc-event-bg-color: var(--calendar-event-default-background);
	--fc-event-border-color: var(--calendar-event-default-background);
	--fc-event-text-color: var(--calendar-event-default-text-color);
	--fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
	--fc-event-bg-color-faded: '';
	--fc-event-border-color-faded: '';
	--fc-event-text-color-faded: '';

	--fc-more-link-bg-color: #d0d0d0;
	--fc-more-link-text-color: inherit;

	--fc-event-resizer-thickness: 8px;
	--fc-event-resizer-dot-total-width: 8px;
	--fc-event-resizer-dot-border-width: 1px;

	--fc-list-event-hover-bg-color: var(--transparent-contrast-subtle);
	
	--fc-right-margin: 40px;
}

html[data-skin-mode='skin-dark'] {
	--fc-today-bg-color: hsla(var(--palette-light-purple-h), var(--palette-light-purple-s), var(--palette-light-purple-l), .1);	
}

.schedule-calendar-component {
	font-size: var(--fc-font-size);
}

.schedule-calendar-component .fc-direction-ltr .fc-timeGridDay-view .fc-timegrid-col-events {
	margin-right: var(--fc-right-margin);
}

.schedule-calendar-component .fc .fc-timegrid-slot {
	height: 2.25em;
}
.schedule-calendar-component .fc .fc-timegrid-slots > table > tbody > tr:hover {
	background-color: var(--transparent-contrast-subtle);
}
.schedule-calendar-component .fc .fc-timegrid-axis-cushion,
.schedule-calendar-component .fc .fc-timegrid-slot-label-cushion {
	font-size: var(--fc-time-slot-font-size);
}
.schedule-calendar-component .fc .fc-timegrid-slot-label {
	vertical-align: top;
}

.schedule-calendar-component .fc .fc-col-header-cell-cushion,
.schedule-calendar-component .fc .fc-list-day-text {
	color: var(--calendar-text);
}

.schedule-calendar-component .fc .fc-button {
	font-size: var(--fc-toolbar-font-size);
}
.schedule-calendar-component .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.schedule-calendar-component .fc .fc-button-primary:not(:disabled):active:focus,
.schedule-calendar-component .fc .fc-button-primary:focus {
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
}

.schedule-calendar-component .fc-view {
	background-color: var(--calendar-background);
}

.schedule-calendar-component .fc-prev-button,
.schedule-calendar-component .fc-next-button {
	font-family: 'FontAwesome';
}
.schedule-calendar-component .fc-header-toolbar .fc-button-primary.fc-today-button {
	background-color: var(--highlight-color);
	border-color: var(--highlight-color);
	color: var(--highlight-color-contrast);
}

.schedule-calendar-component .fc .fc-toolbar-chunk {
	position: relative;
	overflow: visible;
}
.schedule-calendar-component .fc .fc-toolbar-title {
	font-size: var(--fc-toolbar-date-font-size);
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.schedule-calendar-component .fc .fc-toolbar-title:before {
	content: '';
	font-family: 'FontAwesome';
	font-size: .85em;
	margin-right: .5em;
}
.schedule-calendar-component .fc .fc-toolbar-chunk .select-calendar-wrapper {
	position: absolute;
	top: 100%;
	left: calc(0px - (350px / 2) + 50%);
	z-index: 5;
	max-width: none;
	margin-left: 0;
}

.schedule-calendar-component .fc .fc-daygrid-day-number {
	color: var(--calendar-text);
}

/*.schedule-calendar-component .fc .fc-timegrid-event-harness {
	transform-origin: top left;
	transition: transform .15s;
}
.schedule-calendar-component .fc .fc-timegrid-event-harness:hover {
	transform: scale(1.2);
	z-index: 999 !important;
}*/
.schedule-calendar-component .fc .fc-v-event,
.schedule-calendar-component .fc .fc-h-event {
	border-color: var(--transparent-contrast);
}
.schedule-calendar-component .fc .fc-timegrid-event-harness-inset .fc-timegrid-event,
.schedule-calendar-component .fc .fc-timegrid-event.fc-event-mirror, .schedule-calendar-component .fc .fc-timegrid-more-link {
	box-shadow: 0 0 0 1px var(--transparent);
}
.schedule-calendar-component .fc .fc-list-table .fc-event,
.schedule-calendar-component .fc .fc-event-main {
	cursor: pointer;
}
.schedule-calendar-component .fc .fc-event-main {
	padding: 0;
}
.schedule-calendar-component .fc .fc-event-main .fc-event-time,
.schedule-calendar-component .fc .fc-event-main .fc-event-title {
	padding: 2px 4px;
	line-height: 1.25;
}
.schedule-calendar-component .fc .fc-event-main .fc-event-time {
	background-color: var(--transparent-contrast);
	margin: 0;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}
.schedule-calendar-component .fc .fc-event-main .fc-event-title {
	
}
.schedule-calendar-component .fc .fc-daygrid-day-events .fc-event-main .fc-event-time,
.schedule-calendar-component .fc .fc-daygrid-day-events .fc-event-main .fc-event-title {
	padding: 4px;
}
.schedule-calendar-component .fc .fc-daygrid-day-events .fc-event-main .fc-event-time {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.schedule-calendar-component .fc .fc-list-sticky .fc-list-day > * {
	z-index: 1;
}

.schedule-calendar-component .fc .fc-findUnusedUnchecked-button,
.schedule-calendar-component .fc .fc-findUsedUnchecked-button {
	background-color: transparent;
	border-color: var(--fc-button-bg-color);
	color: var(--fc-button-bg-color);
}
.schedule-calendar-component .fc .fc-findUnusedUnchecked-button:hover,
.schedule-calendar-component .fc .fc-findUsedUnchecked-button:hover {
	background-color: transparent;
	border-color: var(--fc-button-hover-bg-color);
	color: var(--fc-button-hover-bg-color);
}

.schedule-calendar-component .fc .fc-event.used-appointment {
	filter: grayscale(1);
}


@media screen and (max-width: 1280px) {
	.schedule-calendar-component .fc .fc-toolbar.fc-header-toolbar {
		display: flex;
		flex-flow: row wrap;
		text-align: center;
		gap: 1em;
	}
	.schedule-calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
		order: -1;
		flex: 0 0 100%;
		width: 100%;
	}
}
@media screen and (max-width: 640px) {
	:root {
		--fc-font-size: 13px;
	}
	
	.schedule-calendar-component .fc .fc-toolbar-chunk .select-calendar-wrapper {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 5;
		max-width: 90vw;
		width: 100%;
		margin-left: 0;
	}
	.schedule-calendar-component .fc .fc-toolbar-chunk .select-calendar {
		width: 100%;
	}
}
@media screen and (max-width: 480px) {
	.schedule-calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
		flex: 0 0 100%;
		width: 100%;
	}
	.schedule-calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
	}
	.schedule-calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) > * {
		flex: 1;
	}
	.schedule-calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(3) > .fc-button-group {
		width: 100%;
	}
	.schedule-calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .fc-button-group:nth-child(3) {
		flex: 0 0 100%;
	}
	
	.schedule-calendar-component .fc .fc-button-group:has(.fc-findUnusedUnchecked-button),
	.schedule-calendar-component .fc .fc-button-group:has(.fc-findUnusedChecked-button),
	.schedule-calendar-component .fc .fc-button-group:has(.fc-findUsedChecked-button),
	.schedule-calendar-component .fc .fc-button-group:has(.fc-findUsedUnchecked-button) {
		flex: 0 0 100%;
		margin-left: 0;
		margin-bottom: .75em;
		order: -1;
	}
}