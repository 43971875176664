/*!
 * https://github.com/arqex/react-datetime
 */

.rdt {
	position: relative;
	color: var(--calendar-text);
}
.rdtPicker {
	display: none;
	position: absolute;
	min-width: 150px;
	max-width: 100%;
	padding: .5em;
	z-index: 3;
	background: var(--calendar-background);
	box-shadow: var(--calendar-shadow);
	border: var(--calendar-border);
	border-top: none;
}
.rdtOpen .rdtPicker {
	display: block;
}
.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
}
.rdtPicker td,
.rdtPicker th {
	text-align: center;
	height: 28px;
}
.rdtPicker td {
	cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: var(--calendar-hover);
	cursor: pointer;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	cursor: not-allowed;
	opacity: .5;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	cursor: not-allowed;
	opacity: .5;
}
.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	cursor: not-allowed;
	opacity: .5;
}
.rdtPicker thead tr:first-of-type th {
	cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
	background: var(--calendar-hover);
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}
.rdtPicker button:hover {
	background: var(--calendar-hover);
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}


.rdtCounters {
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: stretch;
}

.rdtCounters > div {
	float: left;
}

.rdtCounter {
	width: 40px;
}

.rdtCounterSeparator {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1em;
}

.rdtCounter .rdtBtn {
	cursor: pointer;
	display: flex;
	height: 2.5em;
	align-items: center;
	justify-content: center;
	border: var(--calendar-border);
	border-radius: var(--button-border-radius);
	box-shadow: var(--hover-clear-box-shadow);

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;
}
.rdtCounter .rdtBtn:hover {
	box-shadow: var(--hover-box-shadow);
}
.rdtCounter .rdtCount {
	font-size: 1.25em;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.5em;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}

.rdtTime td {
	cursor: default;
}