.wrapper {
	width: 420px;
}

.wrapper .redirectLogin {
	width: 100%;
}

@media screen and (max-width: 640px) {
	.wrapper {
		width: 100%;
		min-width: 320px;
	}
}