.wrapper {
	width: 460px;
}

.successWrapper {
	max-width: 640px;
	background: var(--card-background);
	box-shadow: var(--card-shadow);
	color: var(--card-text);
	padding: var(--padding-section);
	border-radius: var(--card-border-radius);
}
.successWrapper .appName {
	margin-bottom: var(--padding-section);
}
.successWrapper .notice {
	text-align: center;
	padding: 10px 0 30px;
	line-height: 1;
}
.successWrapper .notice > i,
.successWrapper .notice > p {
	margin: 0;
}
.successWrapper .notice > p {
	margin-bottom: 0.25em;
}
.successWrapper .notice > p:last-of-type {
	margin-bottom: 0;
}
.successWrapper .actions {
	margin-top: 1.5rem;
}
.successWrapper .actions button {
	width: 100%;
	margin-bottom: 1rem;
}
.successWrapper .actions button:last-of-type {
	margin-bottom: 0;
}

@media screen and (max-width: 640px) {
	.wrapper,
	.successWrapper {
		width: 100%;
		min-width: 320px;
	}
}