.dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.content {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	z-index: 2;
	width: 90%;
	max-height: 90vh;
	max-height: 90dvh;
	min-height: 0;
	margin: 50px 0;
}

.close {
	position: absolute;
	top: -10px;
	right: -10px;
	display: flex;
	width: 28px;
	height: 28px;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	z-index: 3;
	text-align: center;
	cursor: pointer;
}

.close .icon {
	font-size: 16px;
}

@media screen and (orientation: portrait) {
	.dialog {
		align-items: flex-start;
	}
}