.withIcon {
	padding-left: calc(1rem + 1.25em);
}

.clockIcon {
	position: absolute;
	top: 0;
	left: calc(.5rem + 6px);
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	color: var(--icon-action-color);
	z-index: 1;
}