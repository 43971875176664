.wrapper {
	
}

.wrapper .content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	height: calc(100vh - (var(--header-size) * 2) - 60px);
	height: calc(100dvh - (var(--header-size) * 2) - 60px);
	width: 800px;
	max-width: 90vw;
	margin: 0 auto;
}

.wrapper .content .icon {
	margin-top: 0;
}