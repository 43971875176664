.therapy-component .patient-therapy-select-input .select-input__control {
	border-width: 0 0 1px 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.therapy-component .patient-therapy-select-input .select-input__input-container {
	padding: 0;
	margin: 0;
	height: 100%;
	min-height: 42px;
}
.therapy-component .patient-therapy-select-input .input-toolbar-button.search-btn {
	padding-left: .8em;
	padding-right: .15em;
	color: var(--form-control-text);
}