.wrapper {
	margin-top: var(--section-spacing);
}

.wrapper .title {
	
}

.wrapper .content {
	background-color: var(--form-control-background);
	border: var(--form-control-border);
	border-top: 3px solid var(--popup-tab-active-top-borde-color);
	border-radius: var(--input-border-radisu);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.wrapper .infoError {
	font-size: .875rem;
	padding: .75rem 1rem .75rem;
	cursor: pointer;
}
.wrapper .info {
	font-size: .813rem;
	padding: .75rem 1rem 1rem;
}
.wrapper .info .infoItemBig {
	margin-bottom: .75em;
	line-height: 1.2;
}
.wrapper .info .infoGrid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
 	column-gap: 1em;
	row-gap: .75em;
	margin-top: .75em;
}
.wrapper .info .infoGrid > .infoItem {
	line-height: 1;
}
.wrapper .info .infoItemBig > strong,
.wrapper .info .infoGrid > .infoItem .label {
	margin-right: .5em;
}
.wrapper .info .infoGrid > .infoItem .value {
	
}

.wrapper .actions {
	padding: 0;
	border-bottom: var(--form-control-border);
	background: var(--toolbar-backround);
}
