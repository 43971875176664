.wrapper {
	--z-index-cookie-notice: calc(var(--z-index-overlay) - 1);
	
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}

.wrapper .actions {
	flex: 0 0 auto;
}