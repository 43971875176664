/*
	Default Login component style
	@see 'styleName' prop of the component.
*/

.login-component.default-style {
	
}


.login-component.default-style .login-header {
	
}
.login-component.default-style .login-header .app-name {
	font-size: 1.5rem;
	margin-bottom: 2rem;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
}
.login-component.default-style .login-header .form-title {
	font-size: 2rem;
	line-height: 1;
	font-weight: 600;
	margin-bottom: 1.5rem;
}
.login-component.default-style .login-header .form-desc {
	font-size: 1rem;
	line-height: 1.25;
	font-weight: 200;
	color: var(--text-color-faded);
	margin-top: 0.25em;
}


.login-component.default-style .login-content {
	
}
.login-component.default-style .login-content .login-label {
	
}
.login-component.default-style .login-content .login-input {
	
}
.login-component.default-style .login-content .change-password-btn {
	padding: 0;
	margin: -1rem 0 0 0;
	float: right;
}
.login-component.default-style .login-content .change-password-btn:hover {
	text-decoration: underline;
}
.login-component.default-style .login-content .change-password-btn:focus {
	box-shadow: none;
}


.login-component.default-style .login-actions {
	margin-top: 1.5rem;
}
.login-component.default-style .login-actions button {
	width: 100%;
	margin-bottom: 1rem;
}
.login-component.default-style .login-actions button:last-of-type {
	margin-bottom: 0;
}
.login-component.default-style .login-actions .register-btn {
	
}
.login-component.default-style .login-actions .login-btn {
	
}
.login-component.default-style .login-actions .back-btn {
	
}


.login-component.default-style .component-error {
	
}

