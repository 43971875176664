.wrapper {
	margin-top: .5em;
	margin-bottom: 2rem;
}

.wrapper .grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: .75em;
}
.wrapper .grid .gridItem {
	font-size: 1.2em;
	line-height: 1;
	padding: .75em 1em;
	background-color: var(--card-background-fade);
	border-radius: var(--card-border-radius);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 1em;
}
.wrapper .grid .gridItem.error {
	background-color: var(--error-background-faded);
	color: var(--error-background);
}
.wrapper .grid .gridItem.success {
	background-color: var(--success-background-faded);
	color: var(--success-background);
}
.wrapper .grid .gridItem > label {
	flex: 1 1 100%;
	font-size: 0.875em;
}
.wrapper .grid .gridItem > span {
	flex: 0 0 auto;
	font-size: 1.15em;
	font-weight: 600;
}

.wrapper .grid .gridItem.group {
	align-items: flex-start;
}
.wrapper .grid .gridItem.group > div {
	font-size: .875em;
	flex: 1;
}
.wrapper .grid .gridItem.group > div > .groupTitle {
	font-weight: 900;
	text-transform: uppercase;
	margin-bottom: .5em;
}
.wrapper .grid .gridItem.group > div > .groupValue {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	gap: .5em;
	padding: .35em 0;
	font-size: .875em;
}
.wrapper .grid .gridItem.group > div > .groupValue > * {
	flex: 0 0 auto;
}
.wrapper .grid .gridItem.group > div > .groupValue > label {
	white-space: nowrap;
}
.wrapper .grid .gridItem.group > div > .groupValue > .dots {
	flex: 1;
	height: 1em;
	background: url('../../../../../skin/images/dot.png') left 65% repeat-x;
	opacity: .5;
}
html[data-skin-mode='skin-dark'] .wrapper .grid .gridItem.group > div > .groupValue > .dots {
	background-image: url('../../../../../skin/images/dot_dark.png');
}

@media screen and (max-width: 1280px) {
	.wrapper .grid:has(.gridItem.group) {
		grid-template-columns: 1fr;
		gap: 0;
	}
	.wrapper .grid .gridItem.group:first-child {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		padding-bottom: .5em;
	}
	.wrapper .grid .gridItem.group:last-child {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding-top: .5em;
	}
}
@media screen and (min-width: 769px) {
	.wrapper {
		margin-bottom: 2.5rem;
	}
}
@media screen and (max-width: 359px) {
	.wrapper .grid .gridItem.group > div > .groupValue > label > i {
		display: none;
	}
}