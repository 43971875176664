#search-payments-page #main-page-table thead th:nth-child(4) > .content,
#search-payments-page #main-page-table thead th:nth-child(5) > .content,
#search-payments-page #main-page-table thead th:nth-child(6) > .content,
#search-payments-page #main-page-table thead th:nth-child(7) > .content
{
	justify-content: flex-end;
	text-align: right;
}
#search-payments-page #main-page-table tbody td:nth-child(7) > .content {
	text-align: right;
	white-space: nowrap;
}