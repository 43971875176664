.message {
	max-width: 900px;
	font-size: 13px;
	margin-bottom: 10px;
	padding: 6px 8px;
	line-height: 1;
	border-radius: 3px;
	overflow: hidden;
	pointer-events: auto;
	touch-action: auto;
}
.message:last-of-type {
	margin-bottom: 0;
}
.message.has-close-btn {
	position: relative;
	padding-right: 26px;
}

.close-message-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	width: 20px;
	height: 100%;
	margin: 0;
}
.close-message-btn:hover {
	background-color: rgba(0, 0, 0, 0.15);
	cursor: pointer;
}

.message.default {
	background: var(--default-background);
	color: var(--default-text);
}
.message.info {
	background: var(--info-background);
	color: var(--info-text);
}
.message.help {
	background: var(--help-background);
	color: var(--help-text);
}
.message.warning {
	background: var(--warning-background);
	color: var(--warning-text);
}
.message.error {
	background: var(--error-background);
	color: var(--error-text);
}
.message.success {
	background: var(--success-background);
	color: var(--success-text);
}