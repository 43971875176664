.preview {
	width: 100%;
	height: 100%;
	display: flex !important;
	justify-content: flex-start;
	align-items: center;
}

.preview.invalid:after {
	content: "---";
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	line-height: 1;
	color: var(--text-color-main);
	opacity: 0.5;
}