.separator {
	font-size: 1.2em;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid var(--border-color-main);
}

.separator.noBorder {
	border-bottom: none;
}
.separator.noMargin {
	margin-bottom: 0;
}