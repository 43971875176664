/*
	Default SimpleStaticSearch component style
	@see 'styleName' prop of the component.
*/

html[data-skin-mode='skin-light'] .simple-static-search-component.default-style {
	--inner-box-shadow:
		inset 0px 11px 8px -10px rgba(var(--card-shadow-rgb), 0.15),
		inset 0px -11px 8px -10px rgba(var(--card-shadow-rgb), 0.15);
}

html[data-skin-mode='skin-dark'] .simple-static-search-component.default-style {
	--inner-box-shadow:
		inset 0px 11px 8px -10px rgba(var(--card-shadow-rgb), 0.45),
		inset 0px -11px 8px -10px rgba(var(--card-shadow-rgb), 0.45);
}

.simple-static-search-component.default-style {
	--collapse-button-width: 45px;
	--search-button-size: 50px;
	
	background: var(--card-background);
	color: var(--card-text);
	border: var(--card-border);
}

@media screen and (min-width: 1025px) and (max-width: 1680px) {
	.simple-static-search-component.default-style.rows-1 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-2 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-3 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-4 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-5 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-6 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-7 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-8 .options-form-inner .form-field.label-position-aligned {
		--form-label-width: 180px;
	}
}
@media screen and (min-width: 1440px) {
	.simple-static-search-component.default-style.rows-1 .options-form-inner,
	.simple-static-search-component.default-style.rows-2 .options-form-inner,
	.simple-static-search-component.default-style.rows-3 .options-form-inner,
	.simple-static-search-component.default-style.rows-4 .options-form-inner,
	.simple-static-search-component.default-style.rows-5 .options-form-inner,
	.simple-static-search-component.default-style.rows-6 .options-form-inner,
	.simple-static-search-component.default-style.rows-7 .options-form-inner,
	.simple-static-search-component.default-style.rows-8 .options-form-inner {
		display: flex;
		flex-flow: column wrap;
		max-width: 100%;
		overflow: visible;
		padding-right: 10px;
		margin-top: 12px;
	}
	.simple-static-search-component.default-style.rows-1 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-2 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-3 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-4 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-5 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-6 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-7 .options-form-inner .form-field.label-position-aligned,
	.simple-static-search-component.default-style.rows-8 .options-form-inner .form-field.label-position-aligned {
		width: 50%;
		margin: 0.5rem 0;
	}

	.simple-static-search-component.default-style.rows-1 .options-form-inner {
		/* One standard inputs + one margins by input + 10px just in case */
		max-height: calc(var(--input-height) + 0.75rem + 10px);
	}
	.simple-static-search-component.default-style.rows-2 .options-form-inner {
		/* Two standard inputs + two margins by input + 10px just in case */
		max-height: calc((var(--input-height) * 2) + (0.75rem * 4) + 10px);
	}
	.simple-static-search-component.default-style.rows-3 .options-form-inner {
		/* Three standard inputs + three margins by input + 10px just in case */
		max-height: calc((var(--input-height) * 3) + (0.75rem * 6) + 10px);
	}
	.simple-static-search-component.default-style.rows-4 .options-form-inner {
		/* Four standard inputs + four margins by input + 10px just in case */
		max-height: calc((var(--input-height) * 4) + (0.75rem * 8) + 10px);
	}
	.simple-static-search-component.default-style.rows-5 .options-form-inner {
		/* Five standard inputs + five margins by input + 10px just in case */
		max-height: calc((var(--input-height) * 5) + (0.75rem * 10) + 10px);
	}
	.simple-static-search-component.default-style.rows-6 .options-form-inner {
		/* Six standard inputs + six margins by input + 10px just in case */
		max-height: calc((var(--input-height) * 6) + (0.75rem * 12) + 10px);
	}
	.simple-static-search-component.default-style.rows-7 .options-form-inner {
		/* Seven standard inputs + seven margins by input + 10px just in case */
		max-height: calc((var(--input-height) * 7) + (0.75rem * 14) + 10px);
	}
	.simple-static-search-component.default-style.rows-8 .options-form-inner {
		/* Eight standard inputs + eight margins by input + 10px just in case */
		max-height: calc((var(--input-height) * 8) + (0.75rem * 16) + 10px);
	}
}

.simple-static-search-component.default-style .title {
	padding: 10px calc(15px + var(--collapse-button-width)) 10px 15px;
	font-size: 18px;
	font-weight: 600;
	border-bottom: var(--card-border);
}
.simple-static-search-component.default-style .title:after {
	color: var(--icon-action-color);
}
.simple-static-search-component.default-style .title:hover:after {
	color: var(--icon-action-color-hover);
}
.simple-static-search-component.default-style .title.with-toolbar {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: var(--collapse-button-width);
}
.simple-static-search-component.default-style .title.with-toolbar .title-content {
	padding-top: 10px;
	padding-bottom: 10px;
}
.simple-static-search-component.default-style .title.with-toolbar .title-toolbar {
	flex: 1;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
}


.simple-static-search-component.default-style .content-wrapper {
	background: var(--background-color-secondary);
	box-shadow: var(--inner-box-shadow)
}

.simple-static-search-component.default-style .toolbar {
	margin: -15px -15px 0 -15px;
	border-width: 0 1px 1px 0;
	border-radius: 0;
}

.simple-static-search-component.default-style .options > .form-wrapper {
	padding: 15px calc(15px + var(--search-button-size)) 15px 15px;
}

.simple-static-search-component.default-style .actions {
	position: absolute;
	top: -1px;
	right: 0;
	width: var(--search-button-size);
	height: calc(100% + 1px);
}

.simple-static-search-component.default-style .actions .search-button {
	width: 100%;
	height: 100%;
	border-radius: 0;
	font-size: calc(var(--collapse-button-width) / 2);
	padding: 0;
}

@media screen and (max-width: 640px) {
	.simple-static-search-component.default-style .options {
		padding-bottom: 2rem;
	}

	.simple-static-search-component.default-style .toolbar {
		margin: -1px -20px 0 -20px;
		border-width: 0 0 1px 0;
	}
	
	.simple-static-search-component.default-style .options .form-wrapper {
		padding: 1px 20px 0 20px;
	}
	
	.simple-static-search-component.default-style .actions {
		position: relative;
		top: auto;
		right: auto;
		height: var(--search-button-size);
		width: 100%;
		padding: 0 20px;
	}

	.simple-static-search-component.default-style .actions .search-button {
		border-radius: var(--button-border-radius);
	}
}