.preview {
	--bar-height: 4px;
	
	width: 100%;
	height: 100%;
	position: relative;
	overflow: visible;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	padding-bottom: var(--bar-height);
}

.tooltip {
	width: 100%;
	height: 100%;
}

.symbol {
	
}

.bar {
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: var(--bar-height);
	border-top: var(--form-control-border);
}

.bar > div {
	height: 100%;
}