.data-table-component.therapy-type-list-data-table {
	max-height: 100%;
	background-color: var(--table-background);
	position: relative;
	scrollbar-width: thin;
}
.data-table-component.therapy-type-list-data-table .data-table thead tr:first-of-type {
	display: none;
}

.therapy-type-list:last-of-type .data-table-component.therapy-type-list-data-table tbody {
	cursor: default;
}

@media screen and (max-width: 768px) {
	.data-table-component.therapy-type-list-data-table {
		max-height: none;
	}
}