.wrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-size: 1.5rem;
}

.wrapper .appLogo > img {
	display: block;
	height: 1.25em;
	margin: 0;
}
.wrapper .appLogo.withName > img {
	margin-right: .5em;
}

.wrapper .appName {
	line-height: 1;
}