#create-therapy-dialog > .dialog-content,
#edit-therapy-dialog > .dialog-content {
	max-height: 95vh;
	max-height: 95dvh;
	margin: 25px 0;
}

#create-therapy-dialog .dialog-content-component > .content .form-wrapper,
#edit-therapy-dialog .dialog-content-component > .content .form-wrapper {
	--form-label-width: 250px;
}

#create-therapy-dialog .prescribed-therapies-section,
#edit-therapy-dialog .prescribed-therapies-section {
	background: var(--card-background-fade);
	padding: 30px 25px 40px;
	margin: 0 -24px;
	border-top: 1px dashed var(--border-color-main);
}
#create-therapy-dialog .prescribed-therapies-section-title,
#edit-therapy-dialog .prescribed-therapies-section-title {
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 4px;
}
#create-therapy-dialog .prescribed-therapies-table,
#edit-therapy-dialog .prescribed-therapies-table {
	font-size: .813rem;
}

#create-therapy-dialog .dialog-content-component > .buttons,
#edit-therapy-dialog .dialog-content-component > .buttons {
	padding-top: 0;
}