.checkbox {
	cursor: pointer;
}

.checkbox.disabled,
.checkbox.disabled:hover {
	opacity: 0.5;
	cursor: not-allowed;
}
.checkbox.disabled > * {
	cursor: not-allowed !important;
}
.checkbox.disabled:active {
	box-shadow: none;
}

.checkbox.readOnly {
	opacity: 1;
	cursor: default;
}

.checkbox.withLabel {
	width: auto;
	display: flex;
	align-items: baseline;
}
.checkbox.withLabel .label {
	cursor: pointer;
	margin: 0 1em;
	line-height: 1;
}
.checkbox.withLabel .label.position-left {
	margin-left: 0;
	margin-right: 0.2em;
}
.checkbox.withLabel .label.position-right {
	margin-left: 0.2em;
	margin-right: 0;
}

.checkbox.withLabel.button-checkbox {
	color: var(--text-color-main);
}

.checkbox .label.withTooltip {
	display: block;
}