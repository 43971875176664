.dialog-content-component.type-confirm > .content .confirm-message {
	margin-bottom: 25px;
}
.dialog-content-component.type-confirm > .content .confirm-text-field {
	margin: 0 -25px 0;
	padding: 20px 25px 25px;
	width: calc(100% + 50px);
	background: var(--card-background-fade);
	border-top: 1px solid var(--card-border-color);
}
.dialog-content-component.type-confirm > .content .confirm-text-field > .form-field-label {
	margin-bottom: .25em;
	width: 100%;
	font-weight: normal;
}
.dialog-content-component.type-confirm > .content .confirm-text-field > .form-field-input .text-input-component {
	text-align: inherit;
	font: var(--font-main);
	line-height: 1;
	font-weight: bold;
}
.dialog-content-component.type-confirm > .content .confirm-text-field > .form-field-input .text-input-component::placeholder {
	font-weight: normal;
}
.dialog-content-component.type-confirm > .buttons {
	padding-top: 0;
}