.statistics-component {
	
}

.statistics-component .statistics-submit-section-wrapper {
	padding: .5rem;
	background-color: var(--card-background);
	border: var(--card-border);
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: .5rem;
}
.statistics-component .statistics-submit-section-wrapper button {
	margin: 0;
}

.statistics-component .statistics-result-wrapper {
	margin-top: var(--padding-section);
	display: flex;
	flex-flow: row wrap;
	gap: var(--padding-section);
}
.statistics-component .statistic-type-result {
	
}
.statistics-component .statistic-type-result > h2 {
	margin-top: 0;
	margin-bottom: 10px;
	color: var(--highlight-color);
	line-height: 1.15;
}
.statistics-component .statistic-type-result.chart-type-PIE {
	min-width: 0;
	flex: 1;
}
.statistics-component .statistic-type-result.chart-type-LINE,
.statistics-component .statistic-type-result.chart-type-BAR {
	flex: 0 0 100%;
	min-width: 0;
}

.statistics-component .statistic-type-values {
	--card-grid-margin: 1px;
	
	background-color: var(--card-background);
	border: var(--card-border);
	border-top-width: 4px;
	border-top-color: var(--highlight-color);
	border-bottom: none;
	overflow: hidden;
}
.statistics-component .statistic-type-value {
	border-radius: 0;
	box-shadow: 0 0 0 1px var(--border-color-main);
}
.statistics-component .statistic-type-value.total {
	background-color: var(--card-background-fade);
}

.statistics-component .statistic-type-chart {
	background-color: var(--card-background);
	border: var(--card-border);
}
.statistics-component .statistic-type-chart.empty {
	height: auto !important;
	min-height: 0 !important;
}
.statistics-component .statistic-type-chart.empty .noStatistics {
	font-size: .875em;
	padding: .5em .75em;
	color: var(--text-color-faded);
}
.statistics-component .statistic-type-chart .point-tooltip {
	font-size: .75rem;
	background-color: var(--dropdown-background);
	border-radius: var(--dropdown-border-radius);
	border: var(--dropdown-border);
	box-shadow: var(--dropdown-shadow);
	color: var(--dropdown-text);
	overflow: hidden;
}
.statistics-component .statistic-type-chart .point-tooltip .point-tooltip-title {
	padding: .25em .5em;
	text-align: center;
	font-weight: 900;
}
.statistics-component .statistic-type-chart .point-tooltip .point-tooltip-label {
	display: inline-block;
	padding: .25em .25em .25em .5em;
}
.statistics-component .statistic-type-chart .point-tooltip .point-tooltip-label > .icon {
	margin-right: .25em;
}
.statistics-component .statistic-type-chart .point-tooltip .point-tooltip-value {
	display: inline-block;
	padding: .25em .5em .25em 0;
	color: var(--highlight-color);
	font-weight: 900;
}

@media screen and (max-width: 1336px) {
	.statistics-component .statistic-type-result.chart-type-PIE {
		min-width: 0;
		flex: 0 0 100%;
	}
}

@media screen and (min-width: 1280px) {
	.statistics-component .statistic-type-values {
		--card-grid-margin: 1px;
	}
}

@media screen and (max-width: 768px) {
	.statistics-component .statistics-submit-section-wrapper > span {
		flex: 0 0 100%;
	}
	.statistics-component .statistics-submit-section-wrapper button {
		flex: 1;
	}
}

@media screen and (max-width: 640px) {
	.statistics-component .statistics-submit-section-wrapper button {
		flex: 0 0 calc(50% - .5rem);
	}
}

@media screen and (max-width: 639px) {
	.statistics-component .statistic-type-values .statistic-type-value {
		box-shadow: 0 0 0 1px var(--border-color-faded);
	}
	.statistics-component .statistic-type-values .statistic-type-value > .card-content {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		column-gap: 1em;
		row-gap: .25em;
		padding: .5em;
	}
	.statistics-component .statistic-type-values .statistic-type-value .description,
	.statistics-component .statistic-type-values .statistic-type-value .value {
		font-size: 1em;
		line-height: 1;
	}
	.statistics-component .statistic-type-values .statistic-type-value .description {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 479px) {
	.statistics-component .statistics-submit-section-wrapper button {
		flex: 0 0 100%;
	}
}