.wrapper {
	position: relative;
}

.wrapper .closeBtn {
	position: absolute;
	top: -.75em;
	right: -.75em;
	width: 2em;
	height: 2em;
	line-height: 1;
	background-color: var(--calendar-background) !important;
	color: var(--icon-action-color) !important;
	border-radius: 999em;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 1px 1px 1px 0 var(--border-color-main);
	transform: rotate(-90deg);
}
.wrapper .closeBtn:hover {
	color: var(--icon-action-color-hover);
}
.wrapper .closeBtn i {
	line-height: 1;
	font-size: inherit;
	margin: 0;
}

.wrapper .calendar {
	
}

.wrapper .actions {
	padding: .5em;
	background-color: var(--calendar-now-background);
	border: var(--calendar-border);
	border-top: none;
	box-shadow: var(--calendar-shadow);
}
.wrapper .actions > button {
	margin: 0;
}