#default-app-home-page .page-title {
	text-align: center;
}

.update-appointment-dialog .patient-link-notice {
	border-left-color: var(--transparent-contrast);
	box-shadow: var(--hover-clear-box-shadow);
}
.update-appointment-dialog .patient-link-notice > .icon {
	margin-right: .5em;
}
.update-appointment-dialog .patient-link-notice:hover {
	box-shadow: var(--hover-box-shadow);
}