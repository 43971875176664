.helpIcon {
	font-size: 0.75em;
	color: var(--icon-action-color);
	align-self: flex-start;
	margin-top: 1px;
	transition: var(--transition-hover-out);
}

.helpIcon:hover,
.label:hover .helpIcon 
{
	color: var(--icon-action-color-hover);
	transition: var(--transition-hover-in);
}

.tooltip.help {
	display: flex !important;
}