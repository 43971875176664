#create-appointment-dialog .dialog-content-component > .content .form-wrapper,
#update-regular-appointment-dialog .dialog-content-component > .content .form-wrapper {
	--form-label-width: 200px;
}
#create-appointment-dialog .dialog-content-component > .content .form-field-input.tags .tag:last-child,
#update-regular-appointment-dialog .dialog-content-component > .content .form-field-input.tags .tag:last-child {
	margin-right: 0;
}

@media screen and (max-width: 768px) {
	#create-appointment-dialog .patient-therapy-select-input .select-input__control,
	#update-regular-appointment-dialog .patient-therapy-select-input .select-input__control {
		flex-flow: row wrap;
	}
	#create-appointment-dialog .patient-therapy-select-input .input-toolbar.position-right,
	#update-regular-appointment-dialog .patient-therapy-select-input .input-toolbar.position-right {
		flex: 0 0 100%;
		border-left: none;
		border-top: var(--form-control-border);
		min-height: var(--input-height);
	}
	#create-appointment-dialog .patient-therapy-select-input .input-toolbar.position-right > span:first-child button,
	#update-regular-appointment-dialog .patient-therapy-select-input .input-toolbar.position-right > span:first-child button {
		border-left: none !important;
	}
}
@media screen and (max-width: 640px) {
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button {
		flex: 0 0 50%;
		border-radius: 0;
	}
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button:nth-child(1) {
		order: 3;
	}
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button:nth-child(2) {
		order: 1;
	}
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button:nth-child(3) {
		order: 2;
	}
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button:nth-child(4) {
		order: 4;
	}
}
@media screen and (max-width: 480px) {
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button:nth-child(2),
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button:nth-child(3) {
		flex: 0 0 100%;
	}
	#update-regular-appointment-dialog .dialog-content-component > .buttons > button:nth-child(2) {
		border-bottom: 1px solid var(--transparent);
	}
}