.wrapper {
	--section-spacing: 3rem;
}

.wrapper .sectionTitle {
	font-weight: 900;
	text-transform: uppercase;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	color: var(--base-color-contrast);
}
.wrapper .sectionTitle .label {
	flex: 1;
}
.wrapper .sectionTitle .tools {
	flex: 0 0 auto;
	padding-left: .25em;
}
.wrapper .sectionTitle .tools button {
	padding: 0 .25em;
}
.wrapper .sectionContent {
	margin-top: .5em;
}
.wrapper .sectionActions {
	margin-top: .5em;
	display: flex;
	flex-flow: row wrap;
	gap: 1em;
}
.wrapper .sectionActions > button {
	flex: 1;
	margin: 0;
	padding: .5em;
}
.wrapper .sectionActions > button i {
	margin-right: .35em;
}

.wrapper .contentWrapper .patientRecordWrapper {
	border-radius: 0;
	border-top: none;
	box-shadow: none;
}
.wrapper .contentWrapper .patientRecord {
	display: flex;
	flex-flow: row nowrap;
	padding: 0;
	min-width: 0;
	position: relative;
}
.wrapper .contentWrapper .patientDetails {
	flex: 1;
	position: sticky;
	z-index: 1;
	top: 0;
	left: 0;
	padding: 1em;
	background-color: var(--card-background-fade);
	border: var(--card-border);
	border-style: dashed;
	border-width: 0 1px 0 0;
}
.wrapper .contentWrapper .mainContent {
	flex: 3;
	padding: 1.5em 2em 2em;
	min-width: 0;
}

.wrapper .contentWrapper .actionsSection {
	display: flex;
	flex-flow: row wrap;
	gap: 1em;
	margin: 0;
}
.wrapper .contentWrapper .actionBtn {
	flex: 1;
}

.wrapper .detailsSectionTitle {
	margin-bottom: .25em;
}


@media screen and (min-width: 1024px) and (max-width: 1335px) {
	
}