.cookie-notice-component.default-style {
	z-index: var(--z-index-cookie-notice);
}
body.cookie-settings-dialog-visible .cookie-notice-component.default-style {
	z-index: calc(var(--z-index-dialog) - 1);
}

.cookie-notice-component.default-style {
	background-color: var(--card-background);
	color: var(--card-text);
	border: var(--card-border);
	box-shadow: 0 -2px 4px 0 rgba(var(--card-shadow-rgb), 0.2);
	padding: .75em 1em .75em 1.5em;
	font-size: 1rem;
	font-weight: 300;
}
.cookie-notice-component.default-style > span {
	font-size: 1em;
}
.cookie-notice-component.default-style > span > a {
	color: var(--card-text);
}
.cookie-notice-component.default-style > span > a:hover {
	color: var(--action-color-main);
}

.cookie-notice-component.default-style .actions {
	padding-left: 20px;
}

.cookie-notice-component.default-style .actions .button-component {
	margin-left: 15px;
	padding: .6em 1.2em;
	vertical-align: top;
}
.cookie-notice-component.default-style .actions .close-button {
	font-size: 20px;
	padding: 4px 15px;
}

@media screen and (max-width: 1440px) {
	.cookie-notice-component.default-style {
		padding-right: 80px;
	}
}

@media screen and (max-width: 1024px) {
	.cookie-notice-component.default-style {
		padding-right: 70px;
		font-size: 0.875rem;
	}
	
	.cookie-notice-component.default-style .actions {
		position: relative;
		padding-right: 60px;
	}
	.cookie-notice-component.default-style .actions .button-component {
		display: block;
		width: 100%;
		margin-left: 0;
		margin-bottom: 10px;
	}
	.cookie-notice-component.default-style .actions .button-component:nth-child(2) {
		margin-bottom: 0;
	}
	.cookie-notice-component.default-style .actions .close-button {
		position: absolute;
		top: 0;
		right: 0;
		width: auto;
		height: 100%;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 768px) {
	.cookie-notice-component.default-style {
		font-size: 0.75rem;
	}
	
	.cookie-notice-component.default-style .actions .button-component {
		padding: 7px 15px;
	}
}

@media screen and (max-width: 640px) {
	.cookie-notice-component.default-style {
		flex-flow: column nowrap;
	}
	.cookie-notice-component.default-style > span {
		display: block;
		margin-bottom: 10px;
	}
	.cookie-notice-component.default-style .actions {
		position: unset;
		padding-right: 0;
	}
	.cookie-notice-component.default-style .actions .close-button {
		position: absolute;
		top: 10px;
		right: 10px;
		height: auto;
	}
}