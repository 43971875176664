html[data-skin-mode='skin-light'] #site-section {
	--hero-image-brightness: 100%;
	--hero-title-color: var(--palette-dark-purple);
	--hero-description-color: var(--text-color-main);
	--hero-title-shadow: 1px 1px 0 rgba(255, 255, 255, .5);
	--hero-text-background: rgba(255, 255, 255, .8);
}
html[data-skin-mode='skin-dark'] #site-section {
	--hero-image-brightness: 35%;
	--hero-title-color: var(--palette-light-purple);
	--hero-description-color: #fff;
	--hero-title-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
	--hero-text-background: rgba(0, 0, 0, .35);
}

#site-section {
	
}

#site-section .hero {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: calc(100vh - var(--header-size));
	height: calc(100dvh - var(--header-size));
	min-height: 200px;
	position: relative;
	padding: var(--padding-section);
}
#site-section .hero:before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	filter: brightness(var(--hero-image-brightness));
}
#site-section .hero .hero-title,
#site-section .hero .hero-description {
	max-width: 700px;
	text-align: left;
	color: var(--hero-title-color);
	text-shadow: var(--hero-title-shadow);
}
#site-section .hero .hero-title {
	font-size: 3rem;
	font-weight: 900;
	line-height: 1;
	margin: 0;
	letter-spacing: 0.001em;
	word-spacing: 0.15em;
}
#site-section .hero .hero-description {
	font-size: 1.5rem;
	font-weight: 100;
	line-height: 1.15;
	margin: 0.25em 0 0;
	color: var(--hero-description-color);
}
#site-section .hero .hero-call-to-action {
	font-size: 1.25rem;
	margin-top: 2rem;
	border-radius: 999rem;
	padding: 0.5em 2em;
	white-space: nowrap;
}

@media screen and (max-width: 1024px) {
	#site-section .hero {
		justify-content: flex-start;
		height: calc(80vh - var(--header-size));
		height: calc(80dvh - var(--header-size));
	}
	#site-section .hero .hero-title,
	#site-section .hero .hero-description {
		max-width: 60vw;
	}
	#site-section .hero .hero-title {
		font-size: 2.5rem;
		margin-top: 1em;
	}
	#site-section .hero .hero-description {
		font-size: 1.5rem;
	}
	#site-section .hero .hero-call-to-action {
		font-size: 1.25rem;
	}
}

@media screen and (max-width: 768px) {
	#site-section .hero {
		height: auto;
		min-height: calc(50vh - var(--header-size));
		min-height: calc(50dvh - var(--header-size));
	}
	#site-section .hero .hero-title {
		font-size: 2rem;
		margin-top: 1em;
	}
	#site-section .hero .hero-description {
		font-size: 1.25rem;
	}
	#site-section .hero .hero-call-to-action {
		font-size: 1rem;
	}
}

@media screen and (max-width: 640px) {
	#site-section .hero {
		justify-content: center;
		align-items: center;
	}
	html[data-skin-mode='skin-light'] #site-section .hero:before {
		opacity: .35;
	}
	#site-section .hero .hero-title,
	#site-section .hero .hero-description {
		text-align: center;
		max-width: none;
	}
	#site-section .hero .hero-title {
		margin-top: 0;
	}
	#site-section .hero .hero-description {
		font-weight: 300;
	}
}
@media screen and (max-width: 480px) {
	
}