.wrapper {
	width: 100%;
	position: relative;
}
.wrapper:before {
	content: "";
	font-family: "FontAwesome";
	position: absolute;
	top: 0;
	left: calc(.5rem + 6px);
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	color: var(--icon-action-color);
	z-index: 2;
	pointer-events: none;
}
.wrapper > input {
	padding-left: calc(1rem + 1.25em);
}