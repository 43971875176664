/*
	Key value list integration
 */
.simple-static-search-component .key-value-search-wrapper,
.simple-static-search-component .options-form-inner > .form-field.label-position-none:last-child,
.simple-static-search-component .options-form-inner > .form-field.label-position-stacked:last-child {
	margin: 15px 0 0;
}
.simple-static-search-component .key-value-search-item-wrapper {
	max-width: none;
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component > .item-wrapper {
	display: flex;
	flex-flow: row wrap;
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component > .item-wrapper > .key-value-list-item-component {
	flex: 1 1 50%;
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component > .item-wrapper > .key-value-list-item-component:last-of-type:nth-child(odd) {
	max-width: 50%;
}

.simple-static-search-component.compact-style .key-value-search-item-wrapper {
	border: none !important;
}
.simple-static-search-component.compact-style .key-value-search-wrapper,
.simple-static-search-component.compact-style .options-form-inner > .form-field.label-position-none:last-child,
.simple-static-search-component.compact-style .options-form-inner > .form-field.label-position-stacked:last-child {
	margin: 0;
}
.simple-static-search-component.compact-style .key-value-search-wrapper .key-value-list-component {
	border: none;
	border-radius: 0;
}

@media screen and (max-width: 1440px) {
	.simple-static-search-component .key-value-search-wrapper .key-value-list-component > .item-wrapper > .key-value-list-item-component {
		flex: 1 1 100%;
	}
	.simple-static-search-component .key-value-search-wrapper .key-value-list-component > .item-wrapper > .key-value-list-item-component:last-of-type:nth-child(odd) {
		max-width: none;
	}
}
@media screen and (max-width: 640px) {
	.simple-static-search-component.compact-style .key-value-search-wrapper .key-value-list-component {
		margin: 0;
	}
}


/* Default key value list style ------------------------------------------------------------------------------------- */
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style {
	background: none;
	border: none;
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .key-value-wrapper,
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .key-wrapper,
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .value-wrapper,
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .remove-wrapper {
	margin: 0;
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .remove-wrapper {
	margin-right: var(--key-value-list-item-spacing);
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .key-value-list-item-component.default-style,
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .key-value-wrapper {
	column-gap: var(--key-value-list-item-spacing);
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .bottom-toolbar {
	border-top: none;
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .bottom-toolbar .button-component {
	margin: 1px;
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.default-style .key-value-list-item-component:last-child {
	margin-bottom: var(--key-value-list-item-spacing);
}


/* Compact key value list style ------------------------------------------------------------------------------------- */
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.compact-style .bottom-toolbar .button-component {
	background: var(--background-color-secondary);
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.compact-style .key-value-list-item-component {
	border-bottom: var(--form-control-border);
}
.simple-static-search-component .key-value-search-wrapper .key-value-list-component.compact-style .remove-wrapper {
	border-right: var(--form-control-border);
	border-left: none !important;
}
.simple-static-search-component.compact-style .key-value-search-wrapper .key-value-list-component.default-style {
	margin-left: var(--key-value-list-item-spacing);
}
.simple-static-search-component.compact-style .options-form-inner .key-value-list-component.default-style .form-control:not(.select-input-component),
.simple-static-search-component.compact-style .options-form-inner .key-value-list-component.default-style .select-input-component.form-control .select-input__control {
	border: var(--form-control-border);
	border-radius: var(--input-border-radisu);
}

@media screen and (max-width: 640px) {
	.simple-static-search-component .key-value-search-wrapper .key-value-list-component.compact-style > .item-wrapper {
		padding: 0;
	}
	.simple-static-search-component .key-value-search-wrapper .key-value-list-component.compact-style .remove-wrapper {
		border-left: var(--form-control-border) !important;
	}
}