.rich-text-input-component {
	--toolbar-color: var(--text-color-subtle);
}

.rich-text-input-component .ql-container.ql-snow .ql-editor {
	font: var(--font-main-input);
	min-height: calc((8 * 1.2em) + (12px * 2));
}

.rich-text-input-component .ql-toolbar.ql-snow {
	color: var(--toolbar-color);
	border-top-left-radius: var(--input-border-radisu);
	border-top-right-radius: var(--input-border-radisu);
}
.rich-text-input-component .ql-toolbar.ql-snow .ql-stroke {
	stroke: var(--toolbar-color);
}
.rich-text-input-component .ql-toolbar.ql-snow .ql-fill {
	fill: var(--toolbar-color);
}
.rich-text-input-component .ql-snow.ql-toolbar button:hover, 
.rich-text-input-component .ql-snow .ql-toolbar button:hover, 
.rich-text-input-component .ql-snow.ql-toolbar button:focus, 
.rich-text-input-component .ql-snow .ql-toolbar button:focus, 
.rich-text-input-component .ql-snow.ql-toolbar button.ql-active, 
.rich-text-input-component .ql-snow .ql-toolbar button.ql-active, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label:hover, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label:hover, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label.ql-active, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label.ql-active, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item:hover, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item:hover, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item.ql-selected, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.rich-text-input-component .ql-toolbar.ql-snow .ql-undo:hover:after,
.rich-text-input-component .ql-toolbar.ql-snow .ql-redo:hover:after,
.rich-text-input-component .ql-toolbar.ql-snow .ql-print:hover:after {
	color: var(--action-color-main);
}
.rich-text-input-component .ql-snow.ql-toolbar button:hover .ql-stroke, 
.rich-text-input-component .ql-snow .ql-toolbar button:hover .ql-stroke, 
.rich-text-input-component .ql-snow.ql-toolbar button:focus .ql-stroke, 
.rich-text-input-component .ql-snow .ql-toolbar button:focus .ql-stroke, 
.rich-text-input-component .ql-snow.ql-toolbar button.ql-active .ql-stroke, 
.rich-text-input-component .ql-snow .ql-toolbar button.ql-active .ql-stroke, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, 
.rich-text-input-component .ql-snow.ql-toolbar button:hover .ql-stroke-miter, 
.rich-text-input-component .ql-snow .ql-toolbar button:hover .ql-stroke-miter, 
.rich-text-input-component .ql-snow.ql-toolbar button:focus .ql-stroke-miter, 
.rich-text-input-component .ql-snow .ql-toolbar button:focus .ql-stroke-miter, 
.rich-text-input-component .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, 
.rich-text-input-component .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
	stroke: var(--action-color-main);
}
.rich-text-input-component .ql-snow.ql-toolbar button:hover .ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar button:hover .ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar button:focus .ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar button:focus .ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar button.ql-active .ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar button.ql-active .ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, 
.rich-text-input-component .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
	fill: var(--action-color-main);
}

.rich-text-input-component .ql-snow .ql-picker {
	color: var(--toolbar-color);
}
.rich-text-input-component .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	border: var(--dropdown-border);
	box-shadow: var(--dropdown-shadow);
	background-color: var(--dropdown-background);
	color: var(--dropdown-text);
}

.rich-text-input-component .ql-toolbar.ql-snow .ql-undo:after,
.rich-text-input-component .ql-toolbar.ql-snow .ql-redo:after,
.rich-text-input-component .ql-toolbar.ql-snow .ql-print:after {
	font-family: "FontAwesome";
	line-height: 1;
	font-size: 1.2em;
	color: var(--toolbar-color);
}
.rich-text-input-component .ql-toolbar.ql-snow .ql-undo:after {
	content: "";
}
.rich-text-input-component .ql-toolbar.ql-snow .ql-redo:after {
	content: "";
}
.rich-text-input-component .ql-toolbar.ql-snow .ql-print:after {
	content: "";
}


.rich-text-input-component.form-control {
	padding: 0;
}
.rich-text-input-component.form-control .ql-toolbar.ql-snow,
.rich-text-input-component.form-control .ql-container.ql-snow {
	border: var(--form-control-border);
}
.rich-text-input-component.form-control .ql-toolbar.ql-snow {
	border-width: 0 0 1px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: var(--form-control-label-background);
}
.rich-text-input-component.form-control .ql-container.ql-snow {
	border-width: 0;
}


.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.875em"]:before {
	content: "Small";
}
.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]:before {
	content: "Normal";
}
.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]:before {
	content: "Large";
}
.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]:before {
	content: "Huge";
}
.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.875em"]:before {
	content: "Small";
	font-size: 0.875em !important;
}
.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]:before {
	content: "Normal";
	font-size: 1em !important;
}
.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]:before {
	content: "Large";
	font-size: 1.5em !important;
}
.rich-text-input-component .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]:before {
	content: "Huge";
	font-size: 2.5em !important;
}