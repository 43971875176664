/*
	Compact SimpleStaticSearch component style
	@see 'styleName' prop of the component.
*/

html[data-skin-mode='skin-light'] .simple-static-search-component.compact-style {
	--inner-box-shadow:
		inset 0px 11px 8px -10px rgba(var(--card-shadow-rgb), 0.15),
		inset 0px -11px 8px -10px rgba(var(--card-shadow-rgb), 0.15);
}

html[data-skin-mode='skin-dark'] .simple-static-search-component.compact-style {
	--inner-box-shadow:
		inset 0px 11px 8px -10px rgba(var(--card-shadow-rgb), 0.45),
		inset 0px -11px 8px -10px rgba(var(--card-shadow-rgb), 0.45);
}

.simple-static-search-component.compact-style {
	--collapse-button-width: 45px;
	--search-button-size: 50px;
	--form-field-max-width: none;

	background: var(--card-background);
	color: var(--card-text);
	border: var(--card-border);
	border-bottom: none;
}

.simple-static-search-component.compact-style .title {
	padding: 10px calc(15px + var(--collapse-button-width)) 10px 15px;
	font-size: 18px;
	font-weight: 600;
	border-bottom: var(--card-border);
}
.simple-static-search-component.compact-style .title:after {
	color: var(--icon-action-color);
}
.simple-static-search-component.compact-style .title:hover:after {
	color: var(--icon-action-color-hover);
}
.simple-static-search-component.compact-style .title.with-toolbar {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: var(--collapse-button-width);
}
.simple-static-search-component.compact-style .title.with-toolbar .title-content {
	padding-top: 10px;
	padding-bottom: 10px;
}
.simple-static-search-component.compact-style .title.with-toolbar .title-toolbar {
	flex: 1;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
}

.simple-static-search-component.compact-style .content-wrapper {
	background: var(--form-control-label-background);
	box-shadow: var(--inner-box-shadow), inset 0 -1px 0 0 var(--card-border-color);
}

.simple-static-search-component.compact-style .toolbar {
	margin: 0;
	border-width: 0 1px 1px 0;
	border-radius: 0;
}

.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper {
	padding: 0 var(--search-button-size) 0 0;
	font-size: var(--input-font-size);
	margin-top: 0;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper.hide-actions {
	padding-right: 0;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field {
	margin: -1px 0 0;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field .form-control,
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field .select-input-component.form-control .select-input__control {
	border-radius: 0;
	border: none;
	padding-top: .25rem;
	padding-bottom: .25rem;
	font-size: inherit;
	height: auto;
	min-height: var(--input-height);
	line-height: 1.5;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field .select-input-component.form-control,
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field .select-input-component.form-control .select-input__control {
	padding: 0;
}

.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-label {
	padding: .25rem .5rem;
	margin: 0 -1px;
	font-weight: normal;
	background-color: var(--form-control-label-background);
	color: var(--form-control-label-text);
	border: var(--form-control-border);
	font: var(--font-main-input);
	font-size: .875rem;
	line-height: 1;
	text-align: right;
	justify-content: flex-end;
	flex-basis: calc(((100% - var(--search-button-size)) * .4) + var(--search-button-size));
	min-width: calc(((100% - var(--search-button-size)) * .4) + var(--search-button-size));
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input {
	background: var(--form-control-background);
	border: var(--form-control-border);
}

/* Support for custom fields where field is a select */
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .custom-field-item {
	margin-left: 1px;
	margin-top: 1px;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .custom-field-item .select-input {
	color: var(--form-control-label-text);
	flex-flow: row-reverse nowrap;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .custom-field-item .select-input.select-input__control {
	background: var(--form-control-label-background);
	background-clip: padding-box;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .custom-field-item .select-input.select-input__value-container {
	padding: 0 .5rem 0 0;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .select-input .select-input__value-container > div:first-of-type,
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .select-input .select-input__placeholder,
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .select-input .select-input__menu-list .select-input__option {
	font-size: .875rem;
}


/* Support for compact style of the KeyValueListComponent */
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style {
	border: none;
	border-right: var(--form-control-border);
	border-radius: 0;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .select-input-component.form-control,
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .select-input-component.form-control .select-input__control {
	min-height: 0;
	padding: 0;
	height: 100%;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .select-input-component.form-control .select-input__control {
	background-color: var(--form-control-label-background);
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .select-input-component.form-control .select-input__single-value {
	color: var(--form-control-label-text);
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .key-value-wrapper {
	position: relative;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .key-wrapper {
	flex-basis: calc(((100% - var(--search-button-size)) * .4) + var(--search-button-size) - 1px);
	min-width: calc(((100% - var(--search-button-size)) * .4) + var(--search-button-size) - 1px);
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .value-wrapper {
	padding-right: calc(var(--button-font-size) + 1.2em);
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .remove-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: calc(var(--button-font-size) + 1.2em);
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style > .bottom-toolbar {
	border-top: none;
}
.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style > .bottom-toolbar .button-component {
	border-radius: 0;
	margin: 0;
	font-size: 1em;
	padding: .451rem 1rem;
	border-top: none;
}

.simple-static-search-component.compact-style .actions {
	position: absolute;
	top: -1px;
	right: 0;
	width: var(--search-button-size);
	height: calc(100% + 1px);
	display: flex;
	flex-flow: column nowrap;
}
.simple-static-search-component.compact-style .actions > * {
	flex: 1;
	width: 100%;
	border-radius: 0;
	padding: 0;
	margin: 0;
}
.simple-static-search-component.compact-style .actions > [data-tooltipped] > button {
	width: 100%;
	height: 100%;
	border-radius: 0;
	padding: 0;
	margin: 0;
}
.simple-static-search-component.compact-style .actions .search-button {
	font-size: calc(var(--collapse-button-width) / 2);
}

@media screen and (min-width: 1440px) {
	.simple-static-search-component.compact-style.rows-1 .options-form-inner,
	.simple-static-search-component.compact-style.rows-2 .options-form-inner,
	.simple-static-search-component.compact-style.rows-3 .options-form-inner,
	.simple-static-search-component.compact-style.rows-4 .options-form-inner,
	.simple-static-search-component.compact-style.rows-5 .options-form-inner,
	.simple-static-search-component.compact-style.rows-6 .options-form-inner,
	.simple-static-search-component.compact-style.rows-7 .options-form-inner,
	.simple-static-search-component.compact-style.rows-8 .options-form-inner {
		display: flex;
		flex-flow: column wrap;
		max-width: 100%;
		overflow: visible;
	}
	#root .simple-static-search-component.compact-style:not(.rows-1):not(.rows-2):not(.rows-3):not(.rows-4):not(.rows-5):not(.rows-6):not(.rows-7):not(.rows-8) .options-form-inner > .form-field > .form-field-label {
		flex: 1 1 35%;
		min-width: 0;
	}
	#root .simple-static-search-component.compact-style:not(.rows-1):not(.rows-2):not(.rows-3):not(.rows-4):not(.rows-5):not(.rows-6):not(.rows-7):not(.rows-8) .options-form-inner > .form-field > .form-field-input {
		flex: 1 1 65%;
		min-width: 0;
	}
	.simple-static-search-component.compact-style.rows-1 .options-form-inner > .form-field,
	.simple-static-search-component.compact-style.rows-2 .options-form-inner > .form-field,
	.simple-static-search-component.compact-style.rows-3 .options-form-inner > .form-field,
	.simple-static-search-component.compact-style.rows-4 .options-form-inner > .form-field,
	.simple-static-search-component.compact-style.rows-5 .options-form-inner > .form-field,
	.simple-static-search-component.compact-style.rows-6 .options-form-inner > .form-field,
	.simple-static-search-component.compact-style.rows-7 .options-form-inner > .form-field,
	.simple-static-search-component.compact-style.rows-8 .options-form-inner > .form-field {
		flex: 0 0 auto;
		width: 50%;
		margin: -1px 0 0;
	}

	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .key-wrapper {
		flex: 1 1 calc(35% - 1px);
		min-width: 0;
	}
	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .value-wrapper {
		flex: 1 1 65%;
		min-width: 0;
	}

	.simple-static-search-component.compact-style.rows-1 .options-form-inner {
		/* Three pixels less than 2 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 2) - 3px);
	}
	.simple-static-search-component.compact-style.rows-2 .options-form-inner {
		/* Three pixels less than 3 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 3) - 3px);
	}
	.simple-static-search-component.compact-style.rows-3 .options-form-inner {
		/* Three pixels less than 4 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 4) - 3px);
	}
	.simple-static-search-component.compact-style.rows-4 .options-form-inner {
		/* Three pixels less than 5 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 5) - 3px);
	}
	.simple-static-search-component.compact-style.rows-5 .options-form-inner {
		/* Three pixels less than 6 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 6) - 3px);
	}
	.simple-static-search-component.compact-style.rows-6 .options-form-inner {
		/* Three pixels less than 7 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 7) - 3px);
	}
	.simple-static-search-component.compact-style.rows-7 .options-form-inner {
		/* Three pixels less than 8 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 8) - 3px);
	}
	.simple-static-search-component.compact-style.rows-8 .options-form-inner {
		/* Three pixels less than 9 standard inputs plus one border for each input since bottom margin is -1px */
		max-height: calc(((var(--input-height) + 1px) * 9) - 3px);
	}
}

@media screen and (max-width: 1024px) {
	#root .simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-label {
		flex-basis: calc(var(--form-label-width) - 1.5rem);
	}
}

@media screen and (max-width: 640px) {
	#root .simple-static-search-component.compact-style .options {
		padding: 0;
	}

	#root .simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper {
		padding: 0 1px 0 0;
	}
	#root .simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-label {
		text-align: left;
		justify-content: flex-start;
	}

	#root .simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-label,
	#root .simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input {
		border-left: none;
		border-right: none;
	}
	#root .simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field:not(.label-position-none) > .form-field-label {
		flex-basis: auto;
		margin-bottom: -1px;
		padding: .5rem .5rem 4px;
		min-height: 0;
		display: flex;
		align-items: center;
		background: var(--form-control-background);
		border-right: none;
		border-bottom: none;
	}
	#root .simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input {
		border-top: none;
	}

	.simple-static-search-component.compact-style .options .form-wrapper {
		padding: 0 1px 0 0;
	}

	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .custom-field-item .select-input.select-input__control {
		background: var(--form-control-background);
		background-clip: padding-box;
	}
	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .custom-field-item .select-input {
		flex-flow: row nowrap;
	}
	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .select-input .select-input__value-container > div:first-of-type,
	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .select-input .select-input__placeholder,
	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .select-input .select-input__menu-list .select-input__option {
		text-align: left;
		padding-left: .5rem;
		padding-right: 0;
	}
	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner .custom-field-item .form-control-wrapper.text-input-component-wrapper {
		border-top: none;
	}

	.simple-static-search-component.compact-style .actions {
		position: relative;
		top: auto;
		right: auto;
		height: auto;
		width: 100%;
		padding: 0;
	}
	.simple-static-search-component.compact-style .actions > .button-component,
	.simple-static-search-component.compact-style .actions > .button {
		padding: 0.25em .5em;
		margin: 0;
	}
	
	#root .simple-static-search-component .content-wrapper .options > .form-wrapper .options-form-inner .key-value-list-component .key-wrapper {
		flex-basis: 100%;
	}
	#root .simple-static-search-component .key-value-list-component > .form-wrapper {
		padding-right: 0;
	}
	#root .simple-static-search-component .key-value-list-component {
		border-right: none;
	}
	#root .simple-static-search-component .content-wrapper .options > .form-wrapper .options-form-inner .key-value-list-component > .bottom-toolbar .button-component {
		padding: .75rem 1rem;
	}
	.simple-static-search-component.compact-style .content-wrapper .options > .form-wrapper .options-form-inner > .form-field > .form-field-input .key-value-list-component.compact-style .key-wrapper {
		padding-right: calc(var(--button-font-size) + 1.2em);
	}
}