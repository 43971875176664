.expert-report-dialog .date-and-title {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	gap: 1em;
}
.expert-report-dialog .date-and-title > .date-field {
	flex: 0 0 200px;
}
.expert-report-dialog .date-and-title > .title-field {
	flex: 1;
}
.expert-report-dialog .content-field .form-field-label {
	display: none;
}

.expert-report-dialog .expert-report-type-link {
	padding-right: 0;
}

.expert-report-dialog .tags-wrapper {
	font-size: .875em;
	font-weight: normal;
	margin-bottom: 1rem;
}
.expert-report-dialog .tags-wrapper h3 {
	margin-top: 0;
	margin-bottom: .25em;
}
.expert-report-dialog .tags-wrapper .tags .tag:hover {
	background-color: var(--action-color-main);
	color: var(--action-color-contrast-main);
}

.expert-report-dialog-type-option small {
	opacity: .5;
}

@media screen and (max-width: 640px) {
	.expert-report-dialog .date-and-title {
		display: block;
	}
	.expert-report-dialog .content-field .form-field-label {
		display: block;
	}
}