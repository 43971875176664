.preview {
	width: 100%;
	height: 100%;
	border: 1px solid var(--border-color-main);
	box-shadow: inset 0 0 0 1px var(--background-color-main);
	position: relative;
}

.invalid:after {
	content: "---";
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	line-height: 1;
	text-align: center;
	color: var(--text-color-main);
	opacity: 0.5;
}