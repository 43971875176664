.item > small {
	color: var(--form-control-placeholder-text);
	line-height: 1;
}
.item > div {
	line-height: 1;
	margin-top: -3px;
	margin-bottom: .5em;
	font-weight: 600;
	font-size: .875em;
	white-space: normal;
}