/* Page title ------------------------------------------------------------------------------------------------------- */
.patient-record-component .page-title {
	padding: 0;
	margin: 0;
	font-size: 2rem;
}
.patient-record-component .page-title.stuck {
	border-bottom: none;
	box-shadow: none;
}

/* Page title form field */
.patient-record-component .page-title > .form-field {
	margin-bottom: 0;
}

/* Page title form field label */
.patient-record-component .page-title > .form-field .form-field-label {
	font-weight: 200;
	padding: 10px 0;
	margin-top: 0;
	margin-bottom: calc(0.67em - 10px);
	background: var(--background-color-main);
}
/* Page title form field label: stuck */
.patient-record-component .page-title.stuck > .form-field .form-field-label {
	font-size: 0.75em;
	align-items: center;
	padding-bottom: 8px;
	margin-bottom: 0;
	row-gap: 10px;
	transition: all 100ms ease-in;
}
.patient-record-component .page-title.stuck > .form-field .form-field-label > .content {
	padding: calc(0.167em + 1px) 0;
	transition: all 100ms ease-in;
}

/* Page title form field input */
.patient-record-component .page-title > .form-field .form-field-input .select-input__control {
	font: var(--font-main);
	font-size: 1.25rem;
	border-radius: 0;
	font-weight: 600;
}
.patient-record-component .page-title > .form-field .select-input__value-container {
	padding: .25rem .75rem .25rem 0;
}
.patient-record-component .page-title > .form-field .select-input__placeholder {
	font-weight: normal;
}
.patient-record-component .page-title > .form-field .select-input__single-value {
	color: var(--highlight-color);
}
/* Page title form field input: stuck */
.patient-record-component .page-title.stuck > .form-field .form-field-input .select-input__control {
	font-size: 1rem;
	box-shadow: var(--page-title-box-shadow);
	transition: all 100ms ease-in;
}
.patient-record-component .page-title.stuck > .form-field .select-input__value-container {
	padding: .25rem .5rem .25rem 0;
	transition: all 100ms ease-in;
}
/* Page title responsive */
@media screen and (max-width: 810px) {
	.patient-record-component .page-title.stuck > .form-field .form-field-label.with-actions .content {
		padding-top: 0;
		padding-bottom: 0;
	}
	.patient-record-component .page-title.stuck > .form-field .form-field-label.with-actions .actions {
		margin-bottom: 0;
	}
}

/* Tabs sections */
.patient-record-component .tab-section .popup-tabs {
	font-size: 0.875rem;
	background: none;
	margin: 0;
	padding: 0;
}
.patient-record-component .tab-section .popup-tab {
	background: var(--card-background-fade);
	font-weight: bold;
	font-size: 1em;
}
.patient-record-component .tab-section .popup-tab.active {
	background: var(--card-background);
}
.patient-record-component .tab-section .popup-tab.action,
.patient-record-component .tab-section .popup-tab.action:hover:not(.disabled):not(.active):not(.invalid) {
	background: none;
	border-top-width: 0;
	border-right-width: 0;
	border-bottom-width: 0;
	color: var(--action-color-main);
}

/* Details search */
.patient-record-component .details-section .simple-static-search-component > .title {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
}
.patient-record-component .details-section .simple-static-search-component > .title:after {
	font-family: 'FontAwesome' !important;
	margin-top: 0 !important;
	font-size: 20px !important;
	font-weight: normal;
}
.patient-record-component .details-section .simple-static-search-component.collapsed > .title:after {
	content: "\f00e" !important;
}
.patient-record-component .details-section .simple-static-search-component.expanded > .title:after {
	content: "\f010" !important;
}
.patient-record-component .details-section .simple-static-search-component > .title .horizontal-separator {
	display: inline;
	border-right: 1px solid var(--toolbar-separator-border-color);
	box-shadow: 1px 0 0 0 var(--toolbar-separator-outline-color);
	margin: .5em 0;
}
.patient-record-component .details-section .simple-static-search-component > .title button {
	margin: 0;
	padding-top: .75em;
	padding-bottom: .75em;
	text-align: left;
}
.patient-record-component .details-section .simple-static-search-component > .title > span[data-tooltipped]:has(> .icon) {
	position: absolute;
	top: 0;
	right: 4px;
	z-index: 1;
} 


/* Patient record details section ----------------------------------------------------------------------------------- */
.patient-record-component .details-section {
	margin-top: var(--section-spacing);
}
.patient-record-component .details-section .tab-content-section,
.patient-record-component .details-section .tab-content-section .data-table {
	font-size: .813rem;
}
.patient-record-component .details-section .tab-content-section .data-table-component {
	border-top: none;
	background: var(--card-background);
}
.patient-record-component .details-section .tab-content-section .data-table-component tbody,
.patient-record-component .details-section .tab-content-section .data-table-component tr,
.patient-record-component .details-section .tab-content-section .data-table-component th,
.patient-record-component .details-section .tab-content-section .data-table-component td {
	background: transparent;
}
.patient-record-component .details-section .tab-content-section .data-table-component th {
	background-color: var(--card-background);
}
.patient-record-component .details-section .popup-tab {
	gap: .25em;
}


/* Patient record display type section */
.patient-record-component .display-type-section .popup-tabs {
	padding: 0 0 0 15px;	
}
.patient-record-component .display-type-section .popup-tab {
	font-size: 1rem;
	font-weight: 900;
	text-transform: uppercase;
	background: var(--card-background);
}
.patient-record-component .display-type-section .popup-tab:not(.active) {
	border-top: none;
	border-left: none;
	border-right: none;
	color: var(--text-color-faded);
}
.patient-record-component .display-type-section .popup-tab:not(.active):hover {
	color: var(--action-color-main);
	background: none;
}
.patient-record-component .display-type-section .popup-tab.active {
	border-left: var(--popup-border);
	background: var(--card-background-fade);
	color: var(--base-color-contrast);
	box-shadow: 0 1px 0 0 var(--card-background-fade);
}
.patient-record-component .display-type-section-content {
	padding: calc(var(--section-spacing) / 1.5);
	border-bottom-left-radius: var(--card-border-radius);
	border-bottom-right-radius: var(--card-border-radius);
	background: var(--card-background-fade);
}
.patient-record-component .display-type-section-content .card.action-card-small p {
	font-size: 1rem;
}


/* Responsive */
.patient-details-section .tools .toggle-patient-details-btn,
.therapy-info .therapy-actions {
	display: none;
}
@media screen and (max-width: 1680px) {
	.actions-section .card.action-card-small p {
		display: block;
	}
}
@media screen and (max-width: 1335px) {
	.patient-details-section .section-actions {
		padding-top: .5em;
	}
	.patient-details-section .section-actions .button-component {
		flex: 0 0 100%;
	}
	
	.actions-section .card.action-card-small {
		flex: 1 1 40%;
		width: 40%;
	}

	.patient-record-component .details-section .popup-tab {
		flex-flow: column nowrap;
		align-items: center;
		text-align: center;
	}
	.patient-record-component .details-section .popup-tab > .icon {
		font-size: 1.25em;
		margin-right: 0 !important;
	}
}
@media screen and (max-width: 1280px) {
	.patient-record-component .details-section .tab-content-section {
		border-top: 1px solid var(--table-border-color);
	}
	
	.patient-record-component .details-section .popup-tabs {
		max-width: 100%;
		overflow: auto;
		margin-bottom: -2px;
		padding-bottom: 1px;
	}
	.patient-record-component .details-section .popup-tab {
		padding-top: 1em;
		padding-bottom: 1em;
	}
}
@media screen and (max-width: 1023px) {
	.patient-details-section .tools .edit-patient-btn,
	.patient-details-section .tools .delete-patient-btn {
		display: none;
	}
	
	.main-content .card.action-card-small .card-content .icon,
	.main-content .card.action-card-small .card-content .shortcut {
		display: none;
	}

	.main-content .therapy-section .therapy-info .therapy-info-grid {
		grid-template-columns: 1fr;
	}
}
@media screen and (max-width: 768px) {
	.card > .card-content.patient-record {
		display: block;
	}
	.card > .card-content.patient-record .patient-details-section {
		border-width: 0 0 1px 0;
	}
	.patient-details-section .section-actions .button-component {
		flex: 1 1;
	}
	.patient-details-section .section-content.hide {
		display: none;
	}
	.patient-details-section .tools .toggle-patient-details-btn {
		display: block;
	}

	.card > .card-content.patient-record .main-content {
		padding: .5em 1em 0;
	}
	.card > .card-content.patient-record .main-content .display-type-section-content {
		margin-left: -1em;
		margin-right: -1em;
		padding: 2rem 1em 1em;
	}
	.patient-record-component .display-type-section .popup-tabs {
		padding-left: 0;
		margin-left: calc(-1em - 2px);
		margin-right: calc(-1em - 2px);
	}
	.patient-record-component .display-type-section .popup-tab {
		font-size: 1em;
		padding-top: 1em;
		padding-bottom: 1em;
		flex: 1;
		justify-content: center;
	}
	.patient-record-component .display-type-section .popup-tab:not(.active) {
		border-left: 1px solid transparent;
		border-right: 1px solid transparent;
	}
	.patient-record-component .display-type-section .popup-tab:first-child {
		flex: 2;
		border-left: none;
	}
	.patient-record-component .display-type-section .popup-tab:last-child {
		border-right: none;
	}

	.main-content .patient-statistics-section > .section-content {
		margin-top: 1.5em;
	}
	.main-content .patient-statistics-section > .section-content > div {
		padding-top: 1.5em;
		padding-bottom: 1.5em;
	}

	.therapy-component .patient-therapy-select-input .input-toolbar.position-right {
		display: none;
	}
	.therapy-info .therapy-actions {
		margin-top: 1em;
		display: flex;
		flex-flow: row wrap;
		gap: 1em;
	}
	.therapy-info .therapy-actions .button-component {
		flex: 1;
	}
}
@media screen and (max-width: 480px) {
	.patient-details-section .section-actions .button-component {
		flex: 0 0 100%;
	}

	.main-content .patient-statistics-section > .section-content {
		grid-template-columns: 1fr;
	}

	.main-content .patient-statistics-section > .section-content > div {
		padding-top: .75em;
		padding-bottom: .75em;
	}

	.therapy-info .therapy-actions {
		margin-top: 1.5em;
	}
	.therapy-info .therapy-actions .button-component {
		flex: 0 0 100%;
	}

	.patient-record-component .details-section .simple-static-search-component > .title {
		flex-flow: column nowrap;
	}
	.patient-record-component .details-section .simple-static-search-component > .title .horizontal-separator {
		display: none;
	}
	.patient-record-component .details-section .simple-static-search-component > .title button:first-of-type {
		padding-top: 1.15em;
	}
	.patient-record-component .details-section .simple-static-search-component > .title button:last-of-type {
		padding-bottom: 1.15em;
	}

	.patient-record-component .details-section .mobile-column .sort-select .select-input__value-container {
		font: var(--font-main);
		font-size: .875em;
		font-weight: 600;
		padding-left: 0;
	}

	.patient-record-component .details-section .popup-tab {
		flex-flow: row nowrap;
		align-items: center;
		text-align: left;
		gap: .5em;
		padding: .75em;
		font-weight: 600;
	}
	.patient-record-component .details-section .popup-tab > .icon {
		
	}
	.patient-record-component .details-section .popup-tab > .tab-label {
		display: none;
	}
	.patient-record-component .details-section .popup-tab.active > .tab-label {
		display: inline;
	}
}
@media screen and (max-width: 479px) {
	.main-content .card.action-card-small {
		flex: 0 0 100%;
		width: 100%;
	}
}