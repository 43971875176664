/*
	Card PasswordResetRequest component style
	@see 'styleName' prop of the component.
*/

.password-reset-request-component.card-style {
	background: var(--card-background);
	box-shadow: var(--card-shadow);
	color: var(--card-text);
	padding: var(--padding-section);
	border-radius: var(--card-border-radius);
}


.password-reset-request-component.card-style .password-reset-request-header {

}
.password-reset-request-component.card-style .password-reset-request-header .app-name {
	font-size: 1.5rem;
	margin-bottom: 2rem;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
}
.password-reset-request-component.card-style .password-reset-request-header .form-title {
	font-size: 2rem;
	line-height: 1;
	font-weight: 600;
	margin-bottom: 1.5rem;
}
.password-reset-request-component.card-style .password-reset-request-header .form-desc {
	font-size: 1rem;
	line-height: 1.25;
	font-weight: 200;
	color: var(--text-color-faded);
	margin-top: 0.25em;
}


.password-reset-request-component.card-style .password-reset-request-content {

}
.password-reset-request-component.card-style .password-reset-request-content .change-password-btn {
	padding: 0;
	margin: -1rem 0 0 0;
	float: right;
}
.password-reset-request-component.card-style .password-reset-request-content .change-password-btn:hover {
	text-decoration: underline;
}
.password-reset-request-component.card-style .password-reset-request-content .change-password-btn:focus {
	box-shadow: none;
}


.password-reset-request-component.card-style .password-reset-request-actions {
	margin-top: 1.5rem;
}
.password-reset-request-component.card-style .password-reset-request-actions button {
	width: 100%;
	margin-bottom: 1rem;
}
.password-reset-request-component.card-style .password-reset-request-actions button:last-of-type {
	margin-bottom: 0;
}
.password-reset-request-component.card-style .password-reset-request-actions .send-btn {

}


.password-reset-request-component.card-style .component-error {

}

